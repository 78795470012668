import React, { useState, useEffect, useContext } from 'react';
import FreeProductList from '../../../../components/FreeProductList/FreeProductList';
import { Context as FreeProductsContext } from '../../../../context/FreeProductsContext';
import useInterval from '../../../../hooks/useInterval';
import elementImage from './element.jpeg';

export default function FreeProducts({ offer, onSubmit }) {
	const isLumigen = window.location.href.includes('lumigen');

	const [seconds, setSeconds] = useState(60);
	const [isRunning, setIsRunning] = useState(true);
	const {
		state: { freeProducts },
	} = useContext(FreeProductsContext);

	function countdown() {
		if (seconds <= 0) {
			document.getElementById('countdown').innerHTML = '<span>0</span><span>0</span>';
		} else {
			const first = seconds >= 10 ? seconds.toString()[0] : 0;
			const second = seconds >= 10 ? seconds.toString()[1] : seconds;
			document.getElementById('countdown').innerHTML = `<span>${first}</span><span>${second}</span>`;
		}
		setSeconds(seconds - 1);
	}

	function handleSubmit(productId) {
		onSubmit(productId);
	}

	useInterval(
		() => {
			!isLumigen && countdown();
		},
		isRunning ? 1000 : null
	);

	useEffect(() => {
		import('./FreeProducts.sass');
		setIsRunning(true);
	}, []);

	return (
		<div className='free-products'>
			<div className='free-products__warning'>HURRY UP! LIMITED QUANTITY AVAILABLE</div>
			<div className='free-products__header'>
				<h4>
					{isLumigen
						? 'Last Chance! Maximize Your Results With Element'
						: 'Last Chance! Claim Your Free Gift In '}

					{!isLumigen && (
						<div id='countdown'>
							<span>6</span>
							<span>0</span>
						</div>
					)}
				</h4>
				<p>
					{isLumigen
						? `Reminder: This is your last chance to claim your FREE Element 3-Step Routine (FREE SHIPPING). Don't miss out!`
						: `Congratulations! Your order is eligible for a Free Gift with access to Membership Rewards! Pick one from the options below.`}
				</p>
			</div>
			{isLumigen ? (
				<div className='free-product-element'>
					<img src={elementImage} alt='element' />
					<button className='free-product-list-product-btn' onClick={() => onSubmit({ stickyId: 2407 })}>
						Click here to claim gift
					</button>
				</div>
			) : (
				<FreeProductList isModal={false} productList={freeProducts} onProductClick={handleSubmit} />
			)}

			<div className='free-products__description'>
				{!isLumigen && (
					<i className='far fa-chevron-double-down animate__animated animate__fadeInDown animate__infinite animate__slow'></i>
				)}
				{isLumigen
					? 'This exclusive offer is only available to new Lumigen customers. Included is a 1 month supply of Element Skin Care Vitamin C Serum, Under Eye Vibrancy Cream, and Rejuvenating Night Time Moisturizer, and will subscribe at a monthly discounted rate thereafter of 79.95. Cancel anytime by calling 1-833-542-4906 or by emailing support@mizmuse.com'
					: `This exclusive offer is only available to new Membership Rewards customers. Included is 1 month of free membership services including exclusive discounts, newsletters, access to our extensive video library, and will subscribe at a monthly discounted rate thereafter of $19.97. Cancel anytime by calling ${offer.company.phone} or by emailing ${offer.company.email}`}
			</div>
			<div className='free-product-modal-buttons-btn' id='warranty-no' onClick={() => handleSubmit()}>
				NO, I DON’T LIKE FREE STUFF
			</div>
		</div>
	);
}
