import React, { useEffect, useState } from 'react';
import Reviews from '../../components/Reviews/Reviews';
import OrderForm from '../../components/OrderForm/OrderForm';
import ProductInfo from '../../components/ProductInfo/ProductInfo';
import Banner from '../../components/Banner/Banner';
// import ProofPopup from '../../components/ProofPopup/ProofPopup';
import { buildQueryParams } from '../../utils/helpers';

import './Checkout.sass';
import axios from 'axios';

export default function Checkout({
	offer,
	reviews,
	crossSells,
	onTermsClick,
	onCreateOrder,
	onPrivacyClick,
	isOneLineCheckout,
	isOneProductCheckout,
}) {
	// eslint-disable-next-line no-restricted-globals
	const [banners, setBanners] = useState([]);
	// const [proofPopups, setProofPopups] = useState([]);
	// eslint-disable-next-line no-restricted-globals
	const queryParams = buildQueryParams(location);

	async function getBanners() {
		try {
			const response = await axios.get('https://red-alert-api-d2z82.ondigitalocean.app/banners');
			if (response.data) {
				setBanners(response.data);
			}
			return response.data;
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	// async function getProofPopups() {
	// 	try {
	// 		const response = await axios.get(
	// 			`https://red-alert-api-d2z82.ondigitalocean.app/proofPopups?offerId=${offer._id}`
	// 		);
	// 		if (response.data) {
	// 			setProofPopups(response.data);
	// 		}
	// 		return response.data;
	// 	} catch (error) {
	// 		console.error(error);
	// 		return [];
	// 	}
	// }

	useEffect(() => {
		if (!offer.isBannerDisabled) {
			getBanners();
		}

		// getProofPopups();
		// eslint-disable-next-line
	}, []);

	function getSavingsPercent(oldPrice, price) {
		const decrease = oldPrice - price;
		return (decrease / oldPrice) * 100;
	}

	function getMaxDiscount() {
		let result = 0;

		offer.productsData.forEach((item) => {
			const percent = getSavingsPercent(item.oldPrice, item.price);

			if (percent > result) {
				result = percent;
			}
		});

		return `${result.toFixed()}%`;
	}

	return (
		<>
			<main className='page-main'>
				<Banner banners={banners} discount={getMaxDiscount()} />
				<div className='container'>
					<div className='page-main-layout'>
						<div className='page-main-layout__left'>
							<OrderForm
								offer={offer}
								crossSells={crossSells}
								onCreateOrder={onCreateOrder}
								onPrivacyClick={onPrivacyClick}
								onTermsClick={onTermsClick}
								isOneLineCheckout={isOneLineCheckout}
								isOneProductCheckout={isOneProductCheckout}
							/>
						</div>
						<div
							className={`page-main-layout__right ${isOneProductCheckout && 'page-main-layout__right--opc'}`}
						>
							<ProductInfo offer={offer} />
							<div className='olc-reviews-desktop'>
								{isOneLineCheckout && <Reviews isSlider={false} reviews={reviews} />}
							</div>
						</div>
					</div>
					{!isOneLineCheckout && queryParams.reviews !== '0' && (
						<Reviews isSlider reviews={reviews} offerId={offer._id} />
					)}
					<div className='olc-reviews-mobile'>
						{isOneLineCheckout && <Reviews isSlider={false} reviews={reviews} />}
					</div>
				</div>
			</main>
			{/* <ProofPopup offer={offer} data={proofPopups} /> */}
		</>
	);
}
