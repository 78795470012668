const orderReducer = (state, action) => {
	switch (action.type) {
		case 'SAVE_STEP':
			return {
				...state,
				...action.payload,
			};
		case 'UPDATE_UPSELL':
			return {
				...state,
				upsellCount: action.payload.upsellCount,
				upsellProductIds: action.payload.upsellProductIds,
			};
		case 'FIELD_UPDATE':
			return {
				...state,
				[action.field]: action.payload,
			};
		default:
			return state;
	}
};

export default orderReducer;
