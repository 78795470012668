import './ColorSelect.sass';

export default function ColorSelect(props) {
	const { colors, selectedColor, onChange, defaultProduct } = props;

	const sortedOptions = colors.sort((a, b) => {
		if (a.name === defaultProduct?.color) {
			return -1;
		}

		if (b.name === defaultProduct?.color) {
			return 1;
		}

		return 0;
	});

	return (
		<div className='color-select'>
			<div className='color-select__title'>Choose Your Option</div>
			<div className='color-select-items'>
				{sortedOptions.map((item, index) => (
					<div
						className={`color-select__item ${selectedColor.name === item.name && 'active'}`}
						key={index}
						style={{ width: 100 / colors.length + '%' }}
						onClick={() => onChange(item)}
					>
						<img src={item.imageSrc} alt={item.name} />
						<p>{item.name}</p>
					</div>
				))}
			</div>
		</div>
	);
}
