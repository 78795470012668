import './FreeProductList.sass';

export default function FreeProductList({ productList, isModal, onProductClick }) {
	function renderProduct(product, index) {
		if (product.forModal === isModal) {
			return (
				<div
					key={index}
					className={!product.stickyId ? 'free-product-list-product sold-out' : 'free-product-list-product'}
				>
					<div className='free-product-list-product-stock'>
						<i className='fas fa-exclamation-triangle'></i> {product.stock}
					</div>
					<div style={{ display: 'flex' }}>
						<div className='free-product-list-product-left'>
							<div className='free-product-list-product-title'>
								<p>{product.name}</p>
							</div>
							<div
								className='free-product-list-product-description'
								dangerouslySetInnerHTML={{ __html: product.description }}
							></div>
							<button className='free-product-list-product-btn' onClick={() => onProductClick(product)}>
								Click here to claim gift
							</button>
						</div>
						<div className='free-product-list-product-img'>
							<img src={product.imageSrc} alt='img' />
						</div>
					</div>
				</div>
			);
		}
	}

	return (
		<div
			className={isModal ? 'free-product-list-list free-product-list-list--modal' : 'free-product-list-list'}
		>
			{productList.sort((a, b) => a.order - b.order).map(renderProduct)}
		</div>
	);
}
