/* eslint-disable no-restricted-globals */

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import reviewsImage from './reviews.jpeg';
import reviewsMobImage from './reviews-mob.jpeg';
import './Reviews.sass';

export default function Reviews({ reviews, isSlider, offerId }) {
	if (!reviews.length) return null;

	const sliderSettings = {
		dots: true,
		autoplay: true,
		autoplaySpeed: 7000,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 430,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	function renderReview(item, index) {
		return (
			<div className='review' key={index}>
				<img src={item.imageSrc} alt='review' className='review__img' />
				<div className='review__stars'>
					<i className='fas fa-star'></i>
					<i className='fas fa-star'></i>
					<i className='fas fa-star'></i>
					<i className='fas fa-star'></i>
					<i className='fas fa-star'></i>
				</div>
				<div className='review__title'>{item.title}</div>
				<div className='review__text'>{item.text}</div>
				<div className='review__author'>
					<div className='review__author-name'>{item.name}</div>
					<div className='review__author-location'>{item.location}</div>
				</div>
			</div>
		);
	}

	// knee sleeve offer
	if (offerId === '608b075afb8549001170db18') {
		return (
			<div className='reviews'>
				<div className='reviews__title'>Customer Reviews</div>
				<img src={reviewsImage} alt='reviews' className='reviews__test-img' />
				<img src={reviewsMobImage} alt='reviews' className='reviews__test-img--mob' />
				<button className='reviews__test-button' onClick={() => window.scrollTo(0, 0)}>
					⬆️ back to top ⬆️
				</button>
			</div>
		);
	}

	return (
		<div className='reviews'>
			<div className='reviews__title'>Customer Reviews</div>
			{isSlider ? (
				<Slider {...sliderSettings}>{reviews.map(renderReview)}</Slider>
			) : (
				reviews.map(renderReview)
			)}
		</div>
	);
}
