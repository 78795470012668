import classNames from 'classnames';
import { useEffect, useState } from 'react';

export default function CrossSell({ crossSell, onAdd, upsellProductIds, isND }) {
	const hasVariants = crossSell.variants.length > 0;
	const [productId, setProductId] = useState(null);
	const [variant, setVariant] = useState(null);

	useEffect(() => {
		if (hasVariants) {
			setVariant(crossSell.variants[0]);
			setProductId(crossSell.variants[0].productId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!hasVariants && upsellProductIds.includes(crossSell.productId)) {
			setProductId(crossSell.productId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upsellProductIds]);

	function handleSelectVariant(e) {
		const value = crossSell.variants.find((item) => item.productId === e.target.value);
		setProductId(value.productId);
		setVariant(value);
	}

	function handleAdd() {
		const id = hasVariants ? variant.productId : crossSell.productId;
		const price = crossSell.price.substring(1) * 1;

		setProductId(id);
		onAdd(id, price);
	}

	const active = upsellProductIds.includes(productId);

	if (isND) {
		const itemClasses = classNames({
			'cross-sells-item': true,
			'nd-cross-sells-item': true,
			active,
		});

		return (
			<div className={itemClasses}>
				<div className='cross-sells-item-left-badge'>
					<b>{crossSell.discount} OFF</b>
					<span>Today</span>
				</div>

				<div className='cross-sells-item-inner'>
					<div className='cross-sells-item__img'>
						<img src={variant?.productImg || crossSell.imageSrc} alt={crossSell.name} />
						{hasVariants && (
							<div className='cross-sells-variant-selector'>
								<p>{crossSell.variants[0].name}:</p>
								<select onChange={handleSelectVariant} value={variant?.productId}>
									{crossSell.variants.map((item, index) => (
										<option key={index} value={item.productId}>
											{item.value}
										</option>
									))}
								</select>
							</div>
						)}
					</div>
					<div className='cross-sells-item__description'>
						<h4>{variant?.productName || crossSell.name}</h4>
						<ul>
							{crossSell.benefits.map((benefit, index) => (
								<li key={index}>
									<i className='fas fa-check'></i> {benefit}
								</li>
							))}
						</ul>
					</div>
					<div className='cross-sells-item__price' onClick={() => handleAdd()}>
						<img
							src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/empty-checkmark_nOra5c9OVw.png?updatedAt=1631731001342'
							alt='empty check mark'
							className='cross-sells-item__price-empty-checkmark'
						/>
						<img
							src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/nd-check_rVKZn3OjK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666718943159'
							alt='check mark'
							className='cross-sells-item__price-checkmark'
						/>
						<s>{crossSell.msrp}</s>
						<p>{crossSell.price}</p>
						<div className='cross-sells-item-right-badge'>{crossSell.stock}!</div>
					</div>
				</div>
			</div>
		);
	}

	const itemClasses = classNames({
		'cross-sells-item': true,
		active,
	});

	return (
		<div className={itemClasses}>
			<div className='cross-sells-item-left-badge'>
				<b>{crossSell.discount} OFF</b>
				<span>Today Only</span>
			</div>
			<div className='cross-sells-item-right-badge'>
				<i className='fas fa-exclamation-triangle'></i>
				{crossSell.stock}
			</div>
			<div className='cross-sells-item-inner'>
				<div className='cross-sells-item__img'>
					<img src={variant?.productImg || crossSell.imageSrc} alt={crossSell.name} />
				</div>
				<div className='cross-sells-item__description'>
					<h4>{variant?.productName || crossSell.name}</h4>
					<ul>
						{crossSell.benefits.map((benefit, index) => (
							<li key={index}>
								<i className='fas fa-check'></i> {benefit}
							</li>
						))}
					</ul>
					{hasVariants && (
						<div className='cross-sells-variant-selector'>
							<p>{crossSell.variants[0].name}:</p>
							<select onChange={handleSelectVariant} value={variant?.productId}>
								{crossSell.variants.map((item, index) => (
									<option key={index} value={item.productId}>
										{item.value}
									</option>
								))}
							</select>
						</div>
					)}
				</div>
				<div className='cross-sells-item__price' onClick={() => handleAdd()}>
					<h4>{active ? 'Click to Remove' : 'Click to Add'} </h4>
					<img
						src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/empty-checkmark_nOra5c9OVw.png?updatedAt=1631731001342'
						alt='empty check mark'
						className='cross-sells-item__price-empty-checkmark'
					/>
					<img
						src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/checkmark_rZn7aX0ui.png?updatedAt=1631731001329'
						alt='check mark'
						className='cross-sells-item__price-checkmark'
					/>
					<s>{crossSell.msrp}</s>
					<p>{crossSell.price}</p>
				</div>
			</div>
		</div>
	);
}
