import createDataContext from './createDataContext';
import api from '../api/api';

const freeProductsReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				freeProducts: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadFreeProducts = (dispatch) => async (offerId) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get(`/freeProducts/?offerId=${offerId}`);
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load free products.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	freeProductsReducer,
	{
		loadFreeProducts,
	},
	{
		freeProducts: [],
		error: '',
		loading: false,
	}
);
