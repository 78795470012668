import usps from './usps.png';
import secure from './secure.png';
import './ShipsInfo.sass';

export default function ShipsInfo() {
	return (
		<div className='ships-info-wrapper'>
			<div className='ships-info'>
				<img src={usps} alt='usps' />
				<p>
					All orders ship from the USA 🇺🇸 via <b>USPS</b> within <b>1 business day</b>. A tracking number will
					be issued to your email.
				</p>
			</div>
			<img src={secure} className='ships-info-secure' alt='secure purchase badges' />
		</div>
	);
}
