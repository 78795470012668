/* eslint-disable no-restricted-globals */

import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useHistory } from 'react-router-dom';
import { buildQueryParams } from '../../utils/helpers';
import './Header.sass';

export default function Header(props) {
	const { logoSrc } = props;
	const history = useHistory();
	const queryParams = buildQueryParams(location);
	const isHideCountdown = queryParams.countdown === '0' || history.location.pathname.includes('thank-you');

	function getUrgencyBar() {
		if (isHideCountdown) {
			return null;
		}

		if (history.location.pathname.includes('specialoffer')) {
			return (
				<div className='urgency-bar'>
					<b>Hurry! This limited time offer will expire in</b>
					<Countdown
						date={Date.now() + 60000}
						renderer={({ seconds }) => <b className='urgency-bar__time'>{zeroPad(seconds)} seconds</b>}
					/>
				</div>
			);
		}

		return (
			<div className='urgency-bar'>
				<b>Sale Ending Soon!</b> Your order is reserved for
				<Countdown
					date={Date.now() + 1.2e6}
					renderer={({ minutes, seconds }) => (
						<b className='urgency-bar__time'>
							{zeroPad(minutes)}:{zeroPad(seconds)}
						</b>
					)}
				/>
			</div>
		);
	}

	return (
		<header className='header'>
			<img src={logoSrc} alt='header logo' className='header__logo' />
			{getUrgencyBar()}
		</header>
	);
}
