import createDataContext from './createDataContext';
import api from '../api/api';

const appReducer = (state, action) => {
	switch (action.type) {
		case 'ERROR':
			return {
				...state,
				error: action.payload,
				loading: action.loading,
			};
		case 'COLOR_SELECT':
			return {
				...state,
				selectedColor: action.payload,
				loading: action.loading,
			};
		default:
			return state;
	}
};

const sendMessageFromContactForm = (dispatch) => async (data) => {
	try {
		await api.post(`/contact-us/`, data);
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to send contact us form data',
			loading: false,
		});
	}
};

const productColorSelect = (dispatch) => (data) => {
	dispatch({
		type: 'COLOR_SELECT',
		payload: data,
		loading: false,
	});
};

export const { Context, Provider } = createDataContext(
	appReducer,
	{
		sendMessageFromContactForm,
		productColorSelect,
	},
	{
		error: '',
		selectedColor: null,
		loading: false,
	}
);
