import React from 'react';
import Modal from 'react-modal';
import '../Custom.sass';

export default function PrivacyModal({ company, modalIsOpen, closeModal }) {
	Modal.setAppElement('#root');
	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.75)',
		},
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
		>
			<div className='custom-modal'>
				<button onClick={closeModal} className='custom-modal__close'>
					<i className='far fa-times'></i>
				</button>
				<div className='custom-modal__title'>Privacy Policy</div>
				<p>
					This Privacy Policy governs your use of this Website and any content,
					products or services made available from or through this website
					including any sub domains thereof (&ldquo; Website&rdquo; ). The
					Website is made available by {company.name}. (Hereinafter referred to
					as Company).
				</p>
				<p>
					By visiting this Website and requesting information, products or
					services offered on or through this Website, you agree to the terms of
					this Privacy Policy, as they may be amended from time to time. As
					Company updates or expands its Website, services or products, this
					Privacy Policy may change and the changes are effective upon posting.
					Please check back frequently for updates as it is your sole
					responsibility to be aware of changes. Company does not provide notice
					of changes in any manner other than by posting the changes at this
					Website. This Privacy Policy is incorporated into, and part of, the
					Website Terms and Conditions which govern your use of this Website in
					general.
				</p>
				<p>
					This Website is intended for users who are located in the United
					States of America. The Privacy Policy shall be interpreted under the
					laws of the United States. Website is SSL secure.
				</p>
				<p>
					<strong>Purpose of the Privacy Policy.</strong>
				</p>
				<p>
					This Policy describes the information collection, use, and
					dissemination practices of Company, its parent, subsidiaries and
					registered d/b/a companies (Collectively Referred herein after as
					Company) and all related websites owned or registered to Company. It
					governs Company&rsquo;s right to collect, use, store and disclose
					information provided by You on its (a) this Website, (b)
					Company&rsquo;s other Websites, (c) on various Third Party websites,
					and (d) Company&rsquo;s other information collection and distribution
					practices, including the acquisition of your information from Third
					Parties. Company is not responsible for the information collection or
					privacy practices of third party websites or applications which
					company does not own or control.
				</p>
				<p>Information Collection, Use, and Dissemination practices.</p>
				<p>1.0. Collection of Information.</p>
				<p>
					1.1. User Direct Information. Each time you provide or transmit
					information via the Website, Company may obtain and collect personal
					information about you, including, but not limited to, your name, email
					address, mailing address, bank account information and telephone or
					cell phone number (collectively referred to as &ldquo; Personal
					Information&rdquo; ).
				</p>
				<p>
					1.2. Survey Information. Company may collect Personal Information from
					you when you voluntarily complete a Company survey, order form, or a
					registration page either online by using the internet or offline by
					providing this information through the mail, in person or using a
					telephone. This information may be collected by surveys, order forms,
					or registration pages operated by third parties. This method of
					collection is collectively known as a &ldquo; Survey&rdquo; . In such
					Surveys, Company or a third party may ask you to provide Personal
					Information including your name, email address, street address, zip
					code, telephone numbers (including cell phone numbers and carriers),
					birth date, gender, salary range, education and marital status,
					occupation, employment information, personal and online interests, and
					such other information as may be requested from time to time. Company
					may also collect such information concerning you from another source
					and uses that information in combination with information provided
					from the Website or Surveys. Completing the Surveys is completely
					voluntary, and you are under no obligation to provide Survey
					Information to Company or a third party.
				</p>
				<p>
					1.3. Third Party Information. Company may collect Personal Information
					from you when you provide information to a third party and Company
					subsequently acquires or uses the information provided by the third
					party. Such information may include, but is not limited to, your name,
					email address, street address, zip code, telephone numbers (including
					cell phone numbers and carriers), birth date, gender, salary range,
					education and marital status, occupation, industry of employment,
					personal and online interests, and such other information you may have
					provided to the third party. When acquiring this information, Company
					seeks assurances from the third party that you agreed to provide and
					have such information acquired by Company. Company will not share,
					trade, or sell credit card information to any 3rd party. If you did
					not give express permission, or you would like to remove your
					permission, you may suppress all of your information by sending
					notification to us at {company.email}.
				</p>
				<p>
					1.4. Other Methods of Collecting Personal Information. Other occasions
					when Company obtains information from you include: (1) when you claim
					a prize or seek to redeem an offer by Company or by a third party; (2)
					when you request assistance through Company&rsquo;s customer service
					department; and (3) when you voluntarily subscribe to a Company
					service or newsletter.
				</p>
				<p>
					1.5. Cookies, Web Beacons, and Other Info Collected Using Technology.
					Company currently uses cookie and web beacon technology to associate
					certain Internet-related information about you with information about
					you in its database. Additionally, Company may use other new and
					evolving sources of information in the future.
				</p>
				<p>
					(a) Cookies. &ldquo; Cookies&rdquo; are a feature in your browser
					software. If enabled, we may write cookies that may store small
					amounts of data on your computer about your visit to any of the pages
					of this Site. Cookies assist us in tracking which of our features
					appeal the most to you and what content you may have viewed on past
					visits. When you visit this site again, cookies can enable us to
					customize our content according to your preferences. We may use
					cookies to: keep track of the number of return visits to this site;
					accumulate and report aggregate, statistical information on website
					usage; deliver specific content to you based on your interests or past
					viewing history; save your password for ease of access to our Site.
					You can disable cookies, although the Site may not function properly
					for you. Your browser preferences can be modified to accept or reject
					all cookies, or request a notification when a cookie is set. You may
					read more about cookies at http://cookiecentral.com. In order to use
					all of the features and functionality of Company&rsquo;s websites, you
					need to accept cookies.
					<br />
					(b) Web Beacons. A web beacon is a programming code that can be used
					to display an image on a web page, but can also be used to transfer
					your unique user identification to a database and associate you with
					previously acquired information about an individual in a database.
					This allows Company to track certain websites you visit. Web beacons
					are used to track online behavioral habits for marketing purposes to
					determine products or services you may be interested in. In addition
					to using web beacons on web pages, Company also uses web beacons in
					email messages sent to individuals listed in Company&rsquo;s database.
					<br />
					(c) IP Addresses. Company automatically tracks certain information
					based upon your behavior on the site. We may use this information to
					do internal research on our users&rsquo; demographics, interests, and
					behavior to better understand, protect and serve you and our
					community. This information may include the URL that you just came
					from (whether this URL is on the site or not), which URL you next go
					to (whether this URL is on the site or not), your computer browser
					information, and your IP address. Your Internet Protocol (&ldquo;
					IP&rdquo; ) is a unique Internet &ldquo; address&rdquo; which is
					assigned to you by your Internet Service Provider (&ldquo; ISP&rdquo;
					). For local area network (&ldquo; LAN&rdquo; ), DSL, or cable modem
					users, an IP address may be permanently assigned to a particular
					computer. IP addresses are automatically logged by Web servers,
					collecting information about a user&rsquo;s traffic patterns. While
					the IP address does not identify an individual by name, it may, with
					the cooperation of the ISP, be used to locate and identify an
					individual using the Web. Your IP address can, however, reveal what
					geographic area you are connecting from, or which ISP you are using.
					Finally, other websites you visit have IP addresses, and we may
					collect the IP addresses of those websites and their pages.
					<br />
					(d) Computer Profiles. Company may also collect and accumulate other
					anonymous data which will help us understand and analyze the Internet
					experience of our visitors. For instance, Company may accumulate
					visitor data relating to referring domain names, the type of browsers
					used, operating system software, screen resolutions, color
					capabilities, browser plug-ins, language settings, cookie preferences,
					search engine keywords and JavaScript enablement. When you provide us
					with Personal Identification Information, we are able to use such
					visitor data to identify you.
					<br />
					(e) Data Analysis. Data Analysis technology may be employed from time
					to time if used by a Client of Company.
					<br />
					(f) New Technology. The use of technology on the Internet, including
					cookies and web beacons, is rapidly developing. As a result, Company
					strongly encourages individuals to revisit this policy for any updates
					regarding its use of new technology.
				</p>
				<p>
					1.6. No Information Collected from Children. Company will never
					knowingly collect any Personal Information about children under the
					age of 18. If Company obtains actual knowledge that it has collected
					Personal Information about a child under the age of 18, that
					information will be immediately deleted from its database. Because it
					does not collect such information, Company has no such information to
					use or to disclose to third parties.
				</p>
				<p>
					1.7. Credit Card Information and Bank Account Information. Company
					may, in certain instances, collect credit card numbers, bank account
					information and related information when an individual places an order
					on Company&rsquo;s Website. When the credit card or bank account
					information is submitted to Company, such information is encrypted and
					is protected with SSL encryption software. Company will use the credit
					card information or bank account information for purposes of
					processing and completing the transaction you requested on the
					Website, and the credit card information or bank account information
					will be disclosed to third parties as necessary to complete the
					requested purchase transaction.
				</p>
				<p>2.0. Use of Personal Information.</p>
				<p>
					2.1. General Use. The following paragraphs describe how Company
					currently uses Personal Information, but Company may change or broaden
					its use at any time. As noted below, Company may update this policy
					from time to time. Company may use Personal Information to provide
					promotional offers to individuals by means of email advertising,
					telephone marketing, direct mail marketing, mobile marketing, online
					banner advertising, and package stuffers, among other possible uses.
				</p>
				<p>
					2.2. Email and Unsubscribe Policy. Company uses Personal Information
					to provide third party promotional offers by email to individuals.
					Company may maintain separate email lists for different purposes. If
					email recipients wish to end their email subscription from a
					particular list, they need to follow the instructions at the end of
					each email message to unsubscribe from the particular list. To
					unsubscribe from all Company&rsquo;s email lists, a person must send
					an email to {company.email}
				</p>
				<p>
					Company&rsquo;s unsubscribe process impacts only the future delivery
					of electronic mailings disseminated by Company on its own behalf. You
					may still receive electronic mailings sent on behalf of Third Parties
					and your Personal Information may still be shared with Third Parties
					for use in offline marketing and data appends, including email appends
					unless you unsubscribe as provided above.
				</p>
				<p>
					You should also note that unsubscribing from Company&rsquo;s
					electronic mailings will not automatically unsubscribe your
					information from any Third Party business associates and licensees of
					the data. Since Third Party associates and licensee partners maintain
					separate databases from Company, and you will need to unsubscribe from
					each source individually, if desired. This allows you the freedom to
					pick and choose which subscriptions to maintain and which to
					discontinue.
				</p>
				<p>
					2.3. Content of Email Messages. You may receive certain commercial
					email messages sent by third parties for products, which may be of
					interest to you. In such case, an Advertiser&rsquo;s name will appear
					in the &ldquo; From:&rdquo; and the email will have a method at the
					bottom of the email to unsubscribe from that Advertiser. Company is
					not responsible for third party emails sent to you.
				</p>
				<p>
					2.4. Solicited Email. Company only sends email to individuals who have
					agreed on the Website to receive email from Company or to individuals
					who have agreed on a Third Party website to receive email from Third
					Parties. As such, Company does not send unsolicited email messages. As
					a result, United States statutes requiring certain formatting for
					unsolicited email are not applicable to Company&rsquo;s email
					messages.
				</p>
				<p>
					2.5. Targeted Advertising. Company may use Personal Information to
					target advertising to an individual. When an individual is using the
					Internet, Company uses Technology Information to associate an
					individual with that person&rsquo;s Personal Information, and Company
					attempts to show advertising for products and services in which the
					person has expressed an interest in the Surveys, indicated an interest
					by means of Technology Information, and otherwise. Company may, at its
					discretion, target advertising by using email, direct mail,
					telephones, cell phones, and other means of communication to provide
					promotional offers.
				</p>
				<p>
					2.6. Telemarketing. Company may use Personal Information to advertise,
					directly or indirectly, to individuals using direct mail marketing or
					telemarketing using telephones and cell phones. You may have your
					telephone number or cell phone number listed on a state or federal do
					not call registry. By registering and using this Site, you are giving
					your express permission to the Company and their agents or vendors the
					right to contact you by telephone or cell phone, including the use of
					automated, predictive, programmable, or similar (&ldquo;
					robodialers&rdquo; ) dialers or dialing software. Further, if you are
					residing outside the United States, by registering and using this
					Website, you acknowledge that the processing of your Personal
					Information may involve the transfer of such Personal Information from
					within or outside the European Economic Area (&ldquo; EEA&rdquo; ) to
					countries within or outside the EEA whose data protection regulation
					may not be as stringent as that within the European Union. We may use
					Personal Information to provide the services you&rsquo;ve requested,
					including services that display customized content and advertising.
				</p>
				<p>
					2.7. Wireless Addresses. If the e-mail address you provide to Company
					is a wireless e-mail address, you agree to receive messages at such
					address from Company or its business associates (unless and until you
					have elected not to receive such messages by following the
					instructions in the unsubscribe portion of this policy). You
					understand that your wireless carrier&rsquo;s standard rates apply to
					these messages. You represent that you are the owner or authorized
					user of the wireless device on which messages will be received, and
					that you are authorized to approve the applicable charges.
				</p>
				<p>
					2.8. Short Message Service. Company may make available a service
					through which you can receive messages on your wireless device via
					short message service (&ldquo; SMS Service&rdquo; ). Data obtained
					from you in connection with this SMS service may include your name,
					address, cell phone number, your provider&rsquo;s name, and the date,
					time, and content of your messages. You represent that you are 18
					years of age and the owner or authorized user of the wireless device
					on which messages will be received, and that you are authorized to
					approve the applicable charges. In addition to any fee of which you
					are notified your provider&rsquo;s standard messaging rates apply to
					our confirmation and all subsequent SMS correspondence. All charges
					are billed by and payable to your mobile service provider. Company
					will not be liable for any delays in the receipt of any SMS messages,
					as delivery is subject to effective transmission from your network
					operator. SMS message services are provided on an AS IS basis. Company
					may also obtain the date, time and content of your messages in the
					course of your use of the SMS Service. We will use the information we
					obtain in connection with our SMS Service in accordance with this
					Privacy Policy. If fees are charged to your wireless account invoice,
					we may provide your carrier with your applicable information in
					connection therewith. Your wireless carrier and other service
					providers may also collect data about your wireless device usage, and
					their practices are governed by their own policies.
				</p>
				<p>
					You acknowledge and agree that the SMS Service is provided via
					wireless systems, which use radios (and other means) to transmit
					communications over complex networks. We do not guarantee that your
					use of the SMS Service will be private or secure, and we are not
					liable to you for any lack of privacy or security you may experience.
					You are fully responsible for taking precautions and providing
					security measures best suited for your situation and intended use of
					the SMS Service. We may also access the content of your account and
					wireless account with your carrier for the purpose of identifying and
					resolving technical problems and service-related complaints.
				</p>
				<p>
					You may remove your information from Company&rsquo;s database. If you
					remove your information from Company&rsquo;s database it will no
					longer be used by Company for secondary purposes, disclosed to third
					parties, or used by Company or third parties to send promotional
					correspondence to you. You may remove your information by sending your
					request in writing via email to {company.email} or by sending &ldquo;
					STOP&rdquo; , &ldquo; END&rdquo; , &ldquo; QUIT&rdquo; to the SMS text
					message you have received.
				</p>
				<p>
					2.9. Use of Technology Information. Company may use Technology
					Information (1) to match a person&rsquo;s Personal Information and
					Third Party List Information to other categories of Personal
					Information to make and improve profiles of individuals, (2) to track
					a person&rsquo;s online browsing habits on the Internet, (3) to
					determine which areas of Company&rsquo;s websites are most frequently
					visited. This information helps Company to better understand the
					online habits of individuals so that Company can target advertising
					and promotions to them.
				</p>
				<p>
					2.10. Profiles of Individuals. Company may use Personal Information to
					make a profile of an individual. A profile can be created by combining
					Survey Information and Third Party List Information with other sources
					of Personal Information such as information obtained from public
					databases.
				</p>
				<p>
					2.11. Storage of Personal Information. Company stores the Personal
					Information in a database on its&rsquo; computers. Company computers
					have security measures (such as a firewall and password protections)
					in place to protect against the loss, misuse, and alteration of the
					information under Company&rsquo;s control. Notwithstanding such
					measures, Company cannot guarantee that its security measures will
					prevent its computers from being illegally accessed, and the Personal
					Information on them stolen or altered.
				</p>
				<p>
					3.0. Other Use of Personal Information. Company may use your Personal
					Information in the following ways:
				</p>
				<p>
					3.2. Third Party Offers. Company may provide your Personal Information
					to third parties with whom they have a business relationship for the
					purposes of securing the services and products you have requested or
					provide you with additional marketing materials from which you may
					choose additional products or services. Company may make your Personal
					Information available to companies or other entities not affiliated
					with the Company that have goods, services and offers that might be of
					interest to you so that they may send you these offers.
				</p>
				<p>
					3.3. Marketing. Company will use your Personal Information for any
					marketing and survey purpose on behalf of Company and its affiliates
					and subsidiaries to send information to you about additional goods or
					services that may be of interest to you. In addition, Company will
					disclose your Personal Information to third party agents and
					independent contractors to help us conduct our marketing and survey
					efforts including but not limited to direct marketing. You may have no
					relationship with these other companies.
				</p>
				<p>
					3.5. Service Providers. Company may use third parties to help operate
					our Website and deliver products and services, and may share your
					Personal Information with our affiliates, service providers and other
					third parties that provide products or services for or through this
					Website or for our business (such as Website or database hosting
					companies, address list hosting companies, e-mail service providers,
					analytics companies, distribution companies, fulfillment companies,
					and other similar service providers that use such information on our
					behalf). Unless otherwise stated, these companies do not have any
					right to use the Personal Information we provide to them beyond what
					is necessary for them to assist us. When you conduct e-commerce with
					one of the e-commerce affiliates or service providers to which the
					Website may be linked, certain Personal Information such as your
					e-mail and home address may be collected by the service
					provider&rsquo;s server and made available to us.
				</p>
				<p>
					3.6. Aggregate Statistics. Company may disclose aggregate statistics
					regarding user behavior as a measure of interest in, and use of, our
					Website and e-mails to third parties in the form of aggregate data,
					such as overall patterns or demographic reports that do not describe
					or identify any individual user.
				</p>
				<p>
					3.7. Complying with Legal Process. Company will use or disclose your
					Personal Information in response to subpoenas, court orders, warrants,
					or legal process, or to otherwise establish or exercise our legal
					rights or defend against legal claims or in the event you violate or
					breach an agreement with Company. Company will use and disclose your
					Personal Information if we believe you will harm the property or
					rights of Company, its owners, or those of Company &lsquo;s other
					customers. Finally, we will use or disclose your Personal Information
					if we believe it is necessary to share information in order to
					investigate, prevent, or take action regarding illegal activities,
					suspected fraud, situations involving potential threats to the
					physical safety of any person, violations of Company&rsquo;s
					acceptable use policy, or as otherwise required by law when responding
					to subpoenas, court orders and other legal processes.
				</p>
				<p>
					3.8. Order Fulfillment. Company will transfer your Personal
					Information to third parties when necessary to provide a product or
					service that you order from such third party while using
					Company&rsquo;s websites or when responding to offers provided by
					Company.
				</p>
				<p>
					3.9. Data Summary. Company may sell or transfer non-individualized
					information, such as summary or aggregated anonymous information about
					all persons or sub-groups of persons.
				</p>
				<p>4.0. Privacy Practices of Third Parties.</p>
				<p>
					4.1. Advertiser cookies and web beacons. Advertising agencies,
					advertising networks, and other companies who place advertisements on
					the websites and on the internet generally may use their own cookies,
					web beacons, and other technology to collect information about
					individuals. Company does not control the use of such technology and
					Company has no responsibility for the use of such technology to gather
					information about you.
				</p>
				<p>
					4.2. Links. The websites and email messages sometimes contain
					hypertext links to the websites of third parties. Company is not
					responsible for the privacy practices or the content of such other
					websites. Linked websites may contain links to websites maintained by
					third parties. Such links are provided for your convenience and
					reference only. Company does not operate or control in any respect any
					information, software, products or services available on such third
					party websites. The inclusion of a link to a website does not imply
					any endorsement of the services or the site, its contents, or its
					sponsoring organization.
				</p>
				<p>
					5.0. Foreign use of the Website. To the extent that you are accessing
					the Website while domiciled outside of the United States, you
					acknowledge that the Personal Information you are providing Company is
					collected and stored in the United States and therefore consent to the
					transfer of information to and storage of the information outside of
					your domiciled country and in the United States.
				</p>
			</div>
		</Modal>
	);
}
