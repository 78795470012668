import createDataContext from './createDataContext';
import api from '../api/api';

const promoReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				promo: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadPromo = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get(`/promos/${id}`);
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load promo.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	promoReducer,
	{
		loadPromo,
	},
	{
		promo: null,
		error: '',
		loading: false,
	}
);
