import createDataContext from './createDataContext';
import api from '../api/api';

const orderReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				order: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const createOrder = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/orders/`, data);
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to create order',
		});
	}
};

const updateOrder = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/orders/${id}`, data);
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to update order',
		});
	}
};

const loadOrder = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get(`/orders/${id}`);
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load order',
		});
	}
};

export const { Context, Provider } = createDataContext(
	orderReducer,
	{
		createOrder,
		updateOrder,
		loadOrder,
	},
	{
		order: {},
		error: '',
		loading: false,
	}
);
