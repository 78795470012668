/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import { Context as OrderContext } from '../../context/OrderContext';
import { PageView } from '../../utils/tracking';
import { buildQueryParams } from '../../utils/helpers';
import './ThankYouPage.sass';

export default function ThankYouPage({ company, brand }) {
	const queryParams = buildQueryParams(location);
	const {
		state: { order, loading, error },
		updateOrder,
	} = useContext(OrderContext);
	const history = useHistory();

	const isPayPalOrder = queryParams.alt_pay_method === 'paypal';
	const orderId = order?.processedResponseData?.order_id || queryParams.orderid || '';
	const total = order?.processedResponseData?.orderTotal || queryParams.ordertotal || '';
	const errorText = order?.processedResponseData?.error_message || queryParams.errormessage;

	useEffect(() => {
		if (!isPayPalOrder) {
			updateOrder(queryParams.orderid, { flowIsComplete: true });
		} else {
			updateOrder(localStorage.getItem('orderId'), {
				flowIsComplete: true,
				processedResponseData: queryParams,
			});
		}

		PageView();

		window.dataLayer.push({
			event: 'thankYouPageView',
		});
	}, []);

	const goBackToCheckout = () => {
		const newQueryParams = { ...queryParams };
		delete newQueryParams.orderid;

		// Construct the new URL
		const newPath = `/?${new URLSearchParams(newQueryParams).toString()}`;

		history.push(newPath);
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error error={error} />;
	}

	if (errorText) {
		return (
			<main className='page-main'>
				<div className='container'>
					<div className='thank-you'>
						<div className='thank-you__title'>
							Sorry, we tried to process your order but something went wrong.
						</div>
						<div className='thank-you__text'>{errorText}</div>
						<button onClick={goBackToCheckout} className='try-again'>
							<i className='fa fa-backward'></i> Go Back and Try Again
						</button>
					</div>
				</div>
			</main>
		);
	}

	return (
		<main className='page-main'>
			<div className='container'>
				<div className='thank-you'>
					<div className='thank-you__title'>Thank you for shopping with {brand}</div>
					<div className='thank-you__text'>
						You will receive a confirmation email with a receipt of your recent purchase. If you have any
						questions or concerns, feel free to reach out to us at{' '}
						<span className='inline-block'>{company.email}</span> or by phone{' '}
						<span className='inline-block'>{company.phone}</span>
					</div>
					<div className='thank-you__order-number'>Your order number is - {orderId}</div>
					<div className='thank-you__order-total'>Your total is - ${total}</div>
				</div>
			</div>
		</main>
	);
}
