import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as AppProvider } from './context/AppContext';
import { Provider as OfferProvider } from './context/OfferContext';
import { Provider as OrderProvider } from './context/OrderContext';
import { Provider as SpecialOfferProvider } from './context/SpecialOfferContext';
import { Provider as PromoProvider } from './context/PromoContext';
import { Provider as ReviewsProvider } from './context/ReviewsContext';
import { Provider as CrossSellsProvider } from './context/CrossSellsContext';
import { Provider as FreeProductsProvider } from './context/FreeProductsContext';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<AppProvider>
			<ReviewsProvider>
				<CrossSellsProvider>
					<FreeProductsProvider>
						<PromoProvider>
							<OfferProvider>
								<OrderProvider>
									<SpecialOfferProvider>
										<Router>
											<App />
										</Router>
									</SpecialOfferProvider>
								</OrderProvider>
							</OfferProvider>
						</PromoProvider>
					</FreeProductsProvider>
				</CrossSellsProvider>
			</ReviewsProvider>
		</AppProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
