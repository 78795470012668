import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';
import StepHeader from '../StepHeader/StepHeader';
import ColorSelect from '../ColorSelect/ColorSelect';
import ShipsInfo from '../ShipsInfo/ShipsInfo';
import { Context as AppContext } from '../../context/AppContext';
import { getDollarString, mapColorsFromProducts } from '../../utils/helpers';
import './ProductSelect.sass';

export default function ProductSelect({
	offer,
	orderData,
	onSubmit,
	isOneLineCheckout,
	isOneProductCheckout,
}) {
	const { productsData: products, defaultVariantId, mostPopularProductIds } = offer;

	// eslint-disable-next-line no-restricted-globals
	const parsedQuery = queryString.parse(location.search);
	// eslint-disable-next-line no-restricted-globals
	const [soldOut, setSoldOut] = useState(false);
	// State
	const [selectedProduct, setSelectedProduct] = useState(
		products.find((item) => item.id === orderData.productId) || products[0]
	);

	// Context
	const {
		state: { selectedColor },
		productColorSelect,
	} = useContext(AppContext);

	// Set first product as selected when data is ready
	useEffect(() => {
		let defaultProduct = products[0];

		if (defaultVariantId) {
			defaultProduct = products.find((item) => item.id === defaultVariantId);
		}

		if (defaultVariantId && !selectedColor && defaultProduct) {
			handleColorSelect({
				name: defaultProduct.color,
				imageSrc: defaultProduct.colorImage,
			});
		}

		if (products && defaultProduct) {
			productColorSelect(
				selectedColor
					? selectedColor
					: {
							name: defaultProduct.color,
							imageSrc: defaultProduct.colorImage,
					  }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products]);

	useEffect(() => {
		const currentProducts = sortProductsByColor(products);
		const allSoldOut = currentProducts.every((item) => item.soldOut === '1');

		setSoldOut(allSoldOut);
	}, [products, selectedProduct, sortProductsByColor]);

	function getSavingsPercent(oldPrice, price) {
		const decrease = oldPrice - price;
		return `${((decrease / oldPrice) * 100).toFixed()}%`;
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	function sortProductsByColor(items) {
		if (!selectedColor) {
			return items;
		}

		return items.filter((item) => item.color === selectedColor.name);
	}

	function selectProduct(product) {
		setSelectedProduct(product);

		const total =
			orderData.orderTotal -
			(selectedProduct.price * 1 + selectedProduct.shippingPrice * 1) +
			(product.price * 1 + product.shippingPrice * 1);

		onSubmit(
			{
				productId: product.id,
				productQty: product.quantity,
				productName: product.name,
				productPrice: product.price,
				shippingId: product.shippingId,
				orderTotal: total,
			},
			isOneLineCheckout ? 2 : undefined
		);
	}

	function handleColorSelect(color) {
		productColorSelect(color);

		const newProduct = products.find(
			(item) => item.color === color.name && item.quantity === selectedProduct.quantity
		);
		selectProduct(newProduct);
	}

	function handleSubmit() {
		onSubmit(
			{
				shippingId: selectedProduct.shippingId,
				productId: selectedProduct.id,
				productQty: selectedProduct.quantity,
				productPrice: selectedProduct.price,
				productName: selectedProduct.name,
			},
			2
		);
	}

	function renderProductPrice(price, quantity) {
		if (parsedQuery.each === '1') {
			return `Only ${getDollarString(((price * 1) / quantity) * 1)}/ea`;
		}

		if (parseInt(price) > 0) {
			return `Only ${getDollarString(price)}`;
		}

		return 'Just pay shipping';
	}

	function renderPackage(item, index) {
		if (isOneProductCheckout) {
			return (
				<div
					className={`opcProduct ${
						selectedProduct.id === item.id && products.length > 1 && 'opcProduct--active'
					}`}
					key={index}
					onClick={() => selectProduct(item)}
				>
					<div className='opcProduct-left'>
						<div className='opcProduct__image'>
							<img
								src={selectedColor && selectedColor.name ? selectedColor.imageSrc : offer.productImageSrc}
								alt='product'
							/>
							<div className='opcProduct__quantity'>{item.quantity}</div>
						</div>
						<div className='opcProduct__title' dangerouslySetInnerHTML={{ __html: item.title }}></div>
					</div>

					<div className='opcProduct-right'>
						<div className='opcProduct__msrp'>${item.oldPrice}</div>
						<div className='opcProduct__price'>{getDollarString(item.price, item.quantity)}</div>
					</div>
				</div>
			);
		}

		const isSoldOut = item.soldOut === '1';
		const productClassName = classNames({
			product: true,
			active: selectedProduct.id === item.id,
			popular: mostPopularProductIds.includes(item.id),
			soldOut: isSoldOut,
		});

		return (
			<div className={productClassName} key={index} onClick={() => (!isSoldOut ? selectProduct(item) : null)}>
				{mostPopularProductIds.includes(item.id) && <div className='product-header'>Most Popular</div>}

				<div className='product-body'>
					<div className='product__checkbox'>
						<i className='fas fa-dot-circle'></i>
						<i className='far fa-circle'></i>
					</div>
					<div className='product-left'>
						<div className='product__title' dangerouslySetInnerHTML={{ __html: item.title }}></div>
						{isSoldOut ? (
							<div className='product__shipping' style={{ color: 'red' }}>
								SOLD OUT
							</div>
						) : (
							<div className='product__shipping'>
								{item.shippingPrice * 1 ? `Shipping: $${item.shippingPrice}` : 'FREE SHIPPING'}
							</div>
						)}
					</div>
					{!isSoldOut && (
						<div className='product-right'>
							<div className='product__msrp'>MSRP ${item.oldPrice}</div>
							<div className='product__price'>{renderProductPrice(item.price, item.quantity)}</div>
							<div className='product__savings'>
								You saved {getSavingsPercent(item.oldPrice * 1, item.price * 1)}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}

	if (products.length) {
		return (
			<section className={`product-select ${isOneProductCheckout && 'product-select--opc'}`}>
				{!isOneProductCheckout && <StepHeader number={1} text='Choose Your Deal' />}
				{sortProductsByColor(products).map(renderPackage)}
				{isOneProductCheckout && (
					<div className='opcProduct__shipping'>
						<b>Shipping:</b> <p>{getDollarString(selectedProduct.shippingPrice)}</p>
					</div>
				)}
				{selectedColor && selectedColor.name && (
					<ColorSelect
						colors={mapColorsFromProducts(products)}
						onChange={handleColorSelect}
						selectedColor={selectedColor}
						defaultProduct={products.find((item) => item.id === defaultVariantId)}
					/>
				)}

				{!isOneLineCheckout && (
					<div className='cta-btn-container'>
						<button className='big-green-btn' disabled={soldOut} onClick={() => handleSubmit()}>
							Next Step <i className='fas fa-chevron-right'></i>
						</button>
						<ShipsInfo />
					</div>
				)}
			</section>
		);
	} else {
		return null;
	}
}

ProductSelect.propTypes = {
	onSubmit: PropTypes.func,
	orderData: PropTypes.object,
	products: PropTypes.array,
};
