import { useState } from 'react';
import './ImageOnly.sass';

export default function ImageOnly({ order, specialOffer, onSubmit }) {
	const sortedProducts = specialOffer.products.sort((a, b) => a.qty - b.qty);
	const [selectedProductId, setSelectedProductId] = useState(sortedProducts[0].id);

	const selectedProduct = sortedProducts.find((item) => item.id === selectedProductId);

	function renderProductSelector() {
		return (
			<select
				className='special-offer__select'
				name='productSelect'
				value={selectedProductId}
				onChange={(e) => setSelectedProductId(e.target.value)}
			>
				{sortedProducts.map((item, index) => {
					return (
						<option key={index} value={item.id}>
							{item.qty} {item.name} for {item.price}
							{item.qty > 1 && '/ea'}
						</option>
					);
				})}
			</select>
		);
	}

	return (
		<div className='special-offer'>
			<div className='special-offer__title'>Wait, {order.firstName || 'dear customer'}!</div>
			<div
				className='special-offer__subtitle'
				dangerouslySetInnerHTML={{ __html: specialOffer.headline }}
			></div>
			{specialOffer.gallery.map((item, index) => (
				<img key={index} src={item.source} alt='special offer' className='special-offer__img' />
			))}
			{sortedProducts.length > 1 && renderProductSelector()}
			<div className='special-offer__buttons'>
				<button className='special-offer__buttons--add' onClick={() => onSubmit(selectedProduct)}>
					Yes! Add {selectedProduct.qty} {selectedProduct.name} to my order for {selectedProduct.price}
					{selectedProduct.qty > 1 && '/ea'}
				</button>
				<button className='special-offer__buttons--skip' onClick={() => onSubmit()}>
					No, I don't want it
				</button>
				{specialOffer.disclaimer && (
					<small className='special-offer__disclaimer'>{specialOffer.disclaimer}</small>
				)}
			</div>
		</div>
	);
}
