import axios from 'axios';

const url =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:6005'
		: 'https://red-alert-api-d2z82.ondigitalocean.app';

export default axios.create({
	baseURL: url,
});
