// eslint-disable-next-line import/no-anonymous-default-export
export const errorMessages = {
	email: 'Please enter a valid email',
	firstName: 'Please enter your first name',
	lastName: 'Please enter your last name',
	shippingAddress1: 'Please enter your address',
	shippingCity: 'Please enter your city',
	shippingState: 'Please select your state/province',
	shippingCountry: 'Please select your country',
	shippingZip: 'Please enter your zip/postal code',
	phone: 'Please enter correct phone number',
};
