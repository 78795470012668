import React from 'react';
import './Checkbox.sass';

export default function Checkbox(props) {
	return (
		<label className='checkbox-label'>
			{props.label}
			<input type='checkbox' {...props} />
			<span className='checkmark'></span>
		</label>
	);
}
