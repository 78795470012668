import React from 'react';
import Modal from 'react-modal';
import '../Custom.sass';
import './TermsModal.sass';

export default function TermsModal({ company, modalIsOpen, closeModal, onPrivacyClick }) {
  Modal.setAppElement('#root');
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  // hack for solvolt TC
  if (window.location.href.includes('solvolt')) {
    return (
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className='custom-modal terms-modal'>
          <button onClick={closeModal} className='custom-modal__close'>
            <i className='far fa-times'></i>
          </button>
          <p>
            <strong>TERMS OF SALE</strong>
          </p>
          <p>
            <strong>
              <em>
                IMPORTANT &ndash; PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF SALE BEFORE ACCESSING,
                USING, OR PLACING AN ORDER THROUGH OUR WEBSITE. THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES
                AND LIMITATIONS OF LIABILITIES (see{' '}
              </em>
            </strong>
            <strong>
              <em>Sections 14</em>
            </strong>
            <strong>
              <em> and </em>
            </strong>
            <strong>
              <em>15</em>
            </strong>
            <strong>
              <em>
                ). THESE TERMS FORM AN ESSENTIAL BASIS OF OUR AGREEMENT. PLEASE PRINT AND RETAIN A COPY OF
                THIS AGREEMENT FOR YOUR RECORDS.&nbsp;
              </em>
            </strong>
          </p>
          <p>
            Your use of <a href='https://originaldefense.com'>https://originaldefense.com</a>, including any
            subdomains thereof, affiliated websites, and mobile applications (collectively, the
            &ldquo;Website&rdquo;), which are owned and maintained by Max Media Ventures LLC (&ldquo;ORIGINAL
            DEFENSE,&rdquo; &ldquo;we,&rdquo; &ldquo;our,&rdquo; &ldquo;us&rdquo;), are governed by the
            policies, terms, and conditions set forth below. Please read them carefully. We offer the Website,
            including all information, tools, products and services available from the Website to you, the
            user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            By accessing, using, or placing an order over the Website, you agree to the terms set forth
            herein. If you do not agree to these terms and conditions in their entirety, you are not
            authorized to use the Website in any manner or form whatsoever.
          </p>
          <p>
            <strong>
              THIS AGREEMENT CONTAINS ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO
              A COURT HEARING, RIGHT TO A JURY TRIAL AND RIGHT TO PARTICIPATE IN A CLASS ACTION. ARBITRATION
              IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW IN{' '}
            </strong>
            <strong>SECTION 16</strong>
            <strong>
              {' '}
              OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 16 BELOW
              WHICH DESCRIBES YOUR RIGHT TO OPT-OUT.
            </strong>
          </p>
          <p>
            You can review the most current version of the Terms at any time on this page (
            <a href='https://originaldefense.com/pages/terms-conditions-1'>
              https://originaldefense.com/pages/terms-conditions-1
            </a>
            ). We reserve the right to update, change, or replace any part of these Terms by posting updates
            and/or changes to our Website. It is your responsibility to check this page periodically for
            changes. YOUR CONTINUED USE OF OR ACCESS TO THE WEBSITE FOLLOWING THE POSTING OF ANY CHANGES
            CONSTITUTES BINDING ACCEPTANCE OF THOSE CHANGES.
          </p>
          <p>
            <strong>TABLE OF CONTENTS</strong>
          </p>
          <p>1. Website Use</p>
          <p>2. PRIVACY &amp; SECURITY DISCLOSURE</p>
          <p>3. GENERAL CONDITIONS AND WEBSITE USER CONDUCT RESTRICTIONS&nbsp;</p>
          <p>4. PRODUCTS SOLD FOR PERSONAL USE ONLY, ASSUMPTION OF RISK, AND WAIVER OF LIABILITY&nbsp;</p>
          <p>5. NO MEDICAL ADVICE, AND ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION&nbsp;</p>
          <p>6. MODIFICATIONS TO THE WEBSITE AND PRICES&nbsp;</p>
          <p>7. PAYMENT</p>
          <p>8. ORDER PLACEMENT AND ACCEPTANCE&nbsp;</p>
          <p>9. SUBSCRIPTIONS</p>
          <p>10. SHIPPING&nbsp;</p>
          <p>11. DELIVERY CONFIRMATION&nbsp;</p>
          <p>12. RETURNS AND REFUNDS&nbsp;</p>
          <p>13. SOCIAL MEDIA</p>
          <p>14. DISCLAIMER OF WARRANTIES</p>
          <p>15. DISCLAIMER OF LIABILITIES</p>
          <p>16. Dispute Resolution by Mandatory Binding Arbitration and Class Action Waiver</p>
          <p>17. INDEMNIFICATION</p>
          <p>18. THIRD-PARTY WEBSITES AND LINKS</p>
          <p>19. TESTIMONIALS, REVIEWS, AND OTHER SUBMISSIONS&nbsp;</p>
          <p>20. DIGITAL MILLENNIUM COPYRIGHT ACT NOTICE</p>
          <p>21. ELECTRONIC COMMUNICATIONS&nbsp;</p>
          <p>22. ASSIGNMENT&nbsp;</p>
          <p>23. NO WAIVER&nbsp;</p>
          <p>24. NO AGENCY RELATIONSHIP&nbsp;</p>
          <p>25. SEVERABILITY&nbsp;</p>
          <p>26. TERMINATION</p>
          <p>27. ENTIRE AGREEMENT&nbsp;</p>
          <p>28. QUESTIONS OR ADDITIONAL INFORMATION&nbsp;</p>
          <p>
            <strong>TERMS OF SALE</strong>
          </p>
          <ol>
            <li>
              <strong>WEBSITE USE</strong>
            </li>
          </ol>
          <p>
            By using the Website and agreeing to these Terms, you represent that you are at least the age of
            majority in your state or province of residence. If you use the Website, you are affirming that
            you have the legal capacity to enter into a binding contract with us, and have read this Agreement
            and understand and agree to its terms.
          </p>
          <ol>
            <li>
              <strong>PRIVACY &amp; SECURITY DISCLOSURE</strong>
            </li>
          </ol>
          <p>
            Our privacy policy may be viewed at{' '}
            <a href='https://originaldefense.com/pages/privacy-policy-1'>
              https://originaldefense.com/pages/privacy-policy-1
            </a>
            . The Privacy Policy is hereby incorporated into these Terms by reference and constitute a part of
            these Terms. ORIGINAL DEFENSE reserves the right to modify the privacy policy at its sole
            discretion.&nbsp;
          </p>
          <ol>
            <li>
              <strong>GENERAL CONDITIONS AND WEBSITE USER CONDUCT RESTRICTIONS&nbsp;</strong>
            </li>
          </ol>
          <p>
            All aspects of our Website are protected by U.S. and international copyright, trademark, and other
            intellectual property laws. You do not acquire any ownership or other rights by downloading or
            using the Website or any material on it.&nbsp;
          </p>
          <p>
            You agree not to use or attempt to use the Website or any products or services in any unlawful
            manner. You further agree not to commit any unlawful act or attempt to commit any unlawful act on
            or through the Website including, but not limited to: (1) hacking and other digital or physical
            attacks on the Website; (2) publishing vulgar, obscene, or defamatory material; or (3) any other
            unlawful act.
          </p>
          <ol>
            <li>
              <strong>
                PRODUCTS SOLD FOR PERSONAL USE ONLY, ASSUMPTION OF RISK, AND WAIVER OF LIABILITY&nbsp;
              </strong>
            </li>
          </ol>
          <p>
            ORIGINAL DEFENSE&rsquo;S products and services are for adults only. By using our website and
            placing an order, you represent and warrant that you are at least 18 years old of the age in the
            jurisdiction where you reside. You further agree that any products or services you purchase from
            ORIGINAL DEFENSE and/or our Website will be used for your personal, non-commercial use. You agree
            that you will not resell, re-distribute, modify, or export any product that you order from the
            Website.
          </p>
          <p>
            ORIGINAL DEFENSE is not responsible for any physical harm or damage from the use or misuse of any
            of our products, including a stun gun purchased from our Website. By purchasing any of our
            self-defense devices, including a stun gun, you understand that ORIGINAL DEFENSE does not warrant
            that you may legally purchase, possess, or carry these products according to any federal, state,
            and local laws. You acknowledge that you have determined legality before purchasing any of our
            self-defense devices, including a stun gun, and represent and warrant that you are an adult of at
            least 18 years of age, under no legal disability, have no felony or violent misdemeanor criminal
            record, have no intention of using any of our products to injure any person, and in compliance
            with all applicable federal, state, and local laws in connection with the purchase, ownerhip,
            storage, possession, transport, display, and/or use of our products. You also expressly agree to
            indemnify and hold harmless ORIGINAL DEFENSE for all claims resulting directly or indirectly from
            the purchase, ownership, and/or use of any of our self-defense devices (including a stun gun) in
            compliance or violation of any federal, state, and local laws or regulations.
          </p>
          <p>
            You may not use ORIGINAL DEFENSE&rsquo;S products for any illegal or unauthorized purpose. PLEASE
            CHECK LOCAL STATE LAWS BEFORE ORDERING ANY OF OUR SELF-DEFENSE DEVICES, INCLUDING A STUN GUN, TO
            DETERMINE IF POSSESSION OF THE DEVICE IS LEGAL TO CARRY IN YOUR JURISDICTION.
          </p>
          <ol>
            <li>
              <strong>
                NO MEDICAL ADVICE, AND ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION&nbsp;
              </strong>
            </li>
          </ol>
          <p>
            The material on the Website is provided for general informational purposes only and is not
            intended as medical advice. You should consult your physician before using any information
            provided by ORIGINAL DEFENSE.
          </p>
          <p>
            While we endeavor to provide accurate and current information on our Website, there may be
            information on our Website that contains typographical errors, inaccuracies, or omissions related
            to product descriptions, pricing, promotions, offers, product shipping charges, transit times, and
            availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to
            change or update information or cancel orders if any information on the Website or on any related
            website is inaccurate at any time without prior notice (including after you have submitted your
            order).
          </p>
          <p>
            This Website may contain certain historical information. Historical information, necessarily, is
            not current and is provided for your reference only. We reserve the right to modify the contents
            of this Website at any time, but we have no obligation to update any information on our Website.
            You agree that it is your responsibility to monitor changes to our Website.&nbsp;
          </p>
          <ol>
            <li>
              <strong>MODIFICATIONS TO THE WEBSITE AND PRICES&nbsp;</strong>
            </li>
          </ol>
          <p>
            We reserve the right at any time to modify or discontinue access to the Website (or any part or
            content thereof) without notice at any time. We shall not be liable to you or to any third-party
            for any modification, suspension, or discontinuance of access to the Website. Certain products or
            services may be available exclusively online through the Website. These products or services may
            have limited quantities and are subject to return or exchange only according to our Shipping and
            Returns Policies.&nbsp;
          </p>
          <p>
            We reserve the right to limit the sales of our products to any person, geographic region, or
            jurisdiction. We may exercise this right on a case-by-case basis. All descriptions of products or
            product pricing are subject to change at any time without notice, at our sole discretion. Any
            offer for any product or service made on this Website is void where prohibited.&nbsp;
          </p>
          <ol>
            <li>
              <strong>PAYMENT</strong>
            </li>
          </ol>
          <p>
            All charges are in U.S. Dollars. We accept major credit and debit cards, including those issued by
            Visa, MasterCard, American Express, and Discover.&nbsp;
          </p>
          <p>When placing an order online, you will need:</p>
          <ol>
            <li>The address the card&rsquo;s statement is sent to (billing address);&nbsp;</li>
            <li>The card number and expiration date; and</li>
            <li>The 3 or 4 digit code found only on the card (CVV2 code).</li>
          </ol>
          <p>
            By submitting credit card information or other payment information to us, you represent and agree
            that: (i) you are fully entitled to use that card or account; (ii) all payment information
            provided is complete and accurate; (iii) you will be responsible for any credit card fees; and
            (iv) that sufficient funds exist to pay us the amount(s) due.
          </p>
          <p>
            We and our third-party payment service providers may request, and we may receive, updated credit
            card information from your credit card issuer, such as updated card numbers and expiration date
            information when your credit card has expired. If such updated information is provided to us and
            our third-party payment service providers, we will update your account information accordingly.
            Your credit card issuer may give you the right to opt-out of providing vendors and third-party
            payment service providers with your updated credit card information. If you wish to opt-out of
            your credit card&rsquo;s updating service, you should contact your credit card issuer.
          </p>
          <p>
            We are not responsible for any fees or charges that your bank or credit card issuer may apply. If
            your bank or credit card issuer reverses a charge to your credit card, we may bill you directly
            and seek payment by another method including a mailed statement.&nbsp;
          </p>
          <p>
            By accepting this terms and conditions you hereby acknowledge and agree that we may sell and
            assign our right, title and interests in and to your payment obligation to FlexFactor Operations
            Inc. or its affiliate FlexCorp Inc. (the "Factor"). Note, you will not be impacted by such
            assignment. Any such assignment will be governed by the Factor's End User Agreement (available 
            <a target='_blank' href='https://www.flex-charge.com/flexcharge-end-user-terms-of-service'>
              here
            </a>
            ), and Privacy Policy (available 
            <a target='_blank' href='https://www.flex-charge.com/privacy-policy'>
              here
            </a>
            ). If you have any questions regarding such assignment, you may contact the Factor
            at: customersupport@flexfactor.io.
          </p>
          <ol>
            <li>
              <strong>ORDER PLACEMENT AND ACCEPTANCE&nbsp;</strong>
            </li>
          </ol>
          <p>
            Your electronic order confirmation, or any form of confirmation, does not signify our acceptance
            of your order. We reserve the right to accept or deny shipment to anyone for any reason. In the
            event we deny your order, you will receive a refund to your original form of payment. You
            understand and agree that we will not be liable for any losses or damages that may result from our
            refusal to provide you any service or product. We reserve the right to require additional
            information before processing any order.
          </p>
          <ol>
            <li>
              <strong>SUBSCRIPTIONS</strong>
            </li>
          </ol>
          <p>
            If you go to the Website to purchase a product, you may have the option of buying a product one
            time, or purchasing a subscription. If you purchase a subscription, it is automatically billed
            every thirty (30) days to the credit card you provided when you purchased the subscription and
            mailed to you, until you cancel the subscription. We may use an account updater to automatically
            update your credit card information in the event it changes, in which case, your renewal will be
            billed to the updated account information. If you wish to cancel your subscription, you may do so
            at any time, however, you must cancel your subscription twenty-four (24) hours prior to your next
            shipment going out in order to not receive your next scheduled order. To cancel your subscription,
            simply call Customer Support at 1-800-756-7530, email us at{' '}
            <a href='mailto:support@originaldefense.com'>support@originaldefense.com</a>, or use our
            subscription cancellation web form located on our main webpage and linked{' '}
            <a href='https://originaldefense.com/pages/cancel-your-subscription'>here</a>.&nbsp;
          </p>
          <ol>
            <li>
              <strong>SHIPPING</strong>
            </li>
          </ol>
          <p>
            ORIGINAL DEFENSE ships to addresses located in the United States and internationally via USPS.
            Orders generally ship within 24-48 business hours of the time of placement. Domestic orders
            typically arrive within 3-5 days of shipment. Delivery time for international orders varies based
            on location. Please visit our{' '}
            <a href='https://originaldefense.com/pages/shipping-policy'>Shipping Policy</a> webpage for
            additional terms that may apply to your order.&nbsp;
          </p>
          <p>
            Accurate shipping address and phone number are required. We are not responsible for late
            shipments/missing shipments if you enter incorrect shipping address information. If you discover
            that you have made a mistake with your order after it has been submitted, please contact Customer
            Support immediately by phone or email (1-800-756-7530 /{' '}
            <a href='mailto:support@originaldefense.com'>support@originaldefense.com</a>). You must contact us
            as soon as possible in order to modify or cancel your pending order. However, we frequently ship
            the same day that you order, so we cannot guarantee that we will be able to amend your order in
            accordance with your instructions. &nbsp;
          </p>
          <p>
            <strong>IMPORTANT SHIPPING DISCLAIMER:</strong>
          </p>
          <ul>
            <li>
              The shipping of stun gun products exclude the following regions: Connecticut, Hawaii, Illinois,
              Indiana, Massachusetts, Michigan, North Carolina, Rhode Island, U.S. Virgin Islands, Wisconsin,
              and the District of Columbia (Washington DC).&nbsp;
            </li>
            <li>You have to be legal age 18 and up to purchase a stun gun.</li>
          </ul>
          <ol>
            <li>
              <strong>DELIVERY CONFIRMATION&nbsp;</strong>
            </li>
          </ol>
          <p>
            Because many instances may occur at your delivery address that are beyond our control, you agree
            that any delivery confirmation provided by the carrier is deemed sufficient proof of delivery to
            the cardholder, even without a signature.
          </p>
          <ol>
            <li>
              <strong>RETURNS AND REFUNDS&nbsp;</strong>
            </li>
          </ol>
          <p>
            We want you to love every ORIGINAL DEFENSE purchase you make, but, occasionally, a product may not
            meet your expectations. If you are dissatisfied with your purchase for any reason, please review
            our <a href='https://originaldefense.com/pages/refund-policy'>Returns and Refunds Policy</a> for
            details concerning your specific purchase.{' '}
          </p>
          <p>
            ORIGINAL DEFENSE wants you to be beyond satisfied with your experience with us so we have made
            returning or exchanging products easy. If you are unsatisfied with our product, or if it arrives
            damaged, you may return or exchange the unused portion 30 days from the date that you placed your
            order (the billing date) for a full refund according to the following terms.{' '}
            <strong>You are responsible for the cost of return shipping and shipping costs</strong>{' '}
            <strong>will be deducted from the amount refunded. </strong>You will not be refunded the cost to
            ship the products to you.
          </p>
          <p>
            To obtain a full refund, you must return any empty or unused portion of your order, your return
            must be approved, and the return must be received within 30 days of the date that you placed your
            order (the billing date).
          </p>
          <p>The following terms apply for all returned items:&nbsp;</p>
          <ul>
            <li>
              To initiate a refund or exchange, simply email us at{' '}
              <a href='mailto:support@originaldefense.com'>support@originaldefense.com</a> or call
              1-800-756-7530. You will be given a Return Merchandise Authorization (RMA) number and details
              for shipping.
            </li>
            <li>We cannot process or refund packages marked &ldquo;Return to Sender.&rdquo;</li>
            <li>
              Refunds will be issued to the same credit card or method of payment that was charged when
              ordering the product.
            </li>
            <li>
              To ensure a refund is processed for you, please send returns to the address provided along with
              your RMA number. The RMA number must be clearly written on the package that you are sending
              back. Our shipping department is NOT allowed to accept any packages without an RMA number.
            </li>
          </ul>
          <p>Returned products must be sent to the following address:</p>
          <p>FDC Returns</p>
          <p>RMA #</p>
          <p>P.O. Box 61553</p>
          <p>Savannah, GA 31420</p>
          <p>
            We are not responsible for lost or stolen items. We recommend all returned items to be sent using
            some type of delivery confirmation system to ensure proper delivery.&nbsp;
          </p>
          <p>
            After the shipping department receives your return, it generally takes 5-10 business days or
            sooner to process your refund. Once a return is processed, it usually takes 30 business days for
            this return to be posted to your account, depending on your financial institution. &nbsp;
          </p>
          <ol>
            <li>
              <strong>SOCIAL MEDIA</strong>
            </li>
          </ol>
          <p>
            This section applies to everyone who interacts with our social media presence, including comment
            sections, feeds, and other elements of social media presence viewable on Facebook, Instagram,
            YouTube, Pinterest, Twitter, Google+, LinkedIn, or any of the many other available external
            third-party social media platforms we may utilize (&ldquo;Social Media Presence&rdquo;).&nbsp;
          </p>
          <p>
            Social media platforms are places of public information exchange, and you should have no
            expectation of privacy when using them. Specifically, neither these Terms nor our Privacy
            Statement apply to our Social Media Presence. The sites and platforms that host our Social Media
            Presence are not controlled by us and therefore have their own privacy policies and terms of use.
            The comments and opinions expressed by users on social media are theirs alone and do not reflect
            the opinions of ORIGINAL DEFENSE. Comments that some would consider inappropriate or offensive may
            appear on our Social Media Presence and may remain there until they have been identified by us or
            called to our attention and we are able to work through the necessary procedures and technical
            processes to have them removed. If you see an offensive or inappropriate post or comment on our
            Social Media Presence, you should report it to the operator of the applicable site or platform
            using the procedures they have established for that purpose.
          </p>
          <ol>
            <li>
              <strong> DISCLAIMER OF WARRANTIES</strong>
            </li>
          </ol>
          <p>
            EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THIS WEBSITE AND ALL PRODUCTS PROVIDED
            ARE PROVIDED ON AN &ldquo;AS IS&rdquo;, &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR IMPLIED
            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE MAKE NO, AND EXPRESSLY
            DISCLAIM ANY AND ALL, REPRESENTATIONS AND WARRANTIES AS TO THE RELIABILITY, TIMELINESS, QUALITY,
            SUITABILITY, AVAILABILITY, ACCURACY AND/OR COMPLETENESS OF ANY INFORMATION ON THIS WEBSITE. WE DO
            NOT REPRESENT OR WARRANT, AND EXPRESSLY DISCLAIM THAT: (A) OUR PRODUCTS WILL MEET YOUR
            REQUIREMENTS OR EXPECTATIONS, (B) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS OR
            EXPECTATIONS, OR (C) THE WEBSITE OR THE SERVER(S) THAT MAKE THE WEBSITE AVAILABLE ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER
            EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRD-PARTY RIGHTS, ARE
            HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
          <ol>
            <li>
              <strong>DISCLAIMER OF LIABILITIES</strong>
            </li>
          </ol>
          <p>
            EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, IN NO EVENT SHALL ORIGINAL DEFENSE OR
            ANY OF ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS,
            TELECOMMUNICATIONS PROVIDERS, AND/OR AGENTS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
            EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES, FEES, COSTS OR CLAIMS ARISING FROM OR
            RELATED TO THIS AGREEMENT, THE PRIVACY POLICY, THE PRODUCTS, OR YOUR OR A THIRD-PARTY&rsquo;S USE
            OR ATTEMPTED USE OF THE WEBSITE OR ANY PRODUCT, REGARDLESS OF WHETHER ORIGINAL DEFENSE HAS HAD
            NOTICE OF THE POSSIBILITY OF SUCH DAMAGES, FEES, COSTS, OR CLAIMS. THIS INCLUDES, WITHOUT
            LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA, LOSS OF GOODWILL, COST OF PROCUREMENT
            OF SUBSTITUTE SERVICES OR PRODUCTS, OR ANY OTHER INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
            CONSEQUENTIAL, OR OTHER DAMAGES. THIS APPLIES REGARDLESS OF THE MANNER IN WHICH DAMAGES ARE
            ALLEGEDLY CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE AND STRICT LIABILITY), WARRANTY, OR OTHERWISE. IF, NOTWITHSTANDING THE LIMITATIONS OF
            LIABILITY SET FORTH ABOVE, ORIGINAL DEFENSE IS FOUND LIABLE UNDER ANY THEORY, ORIGINAL
            DEFENSE&rsquo;S LIABILITY AND YOUR EXCLUSIVE REMEDY WILL BE LIMITED TO USD $500.00. THIS
            LIMITATION OF LIABILITY SHALL APPLY FOR ALL CLAIMS, REGARDLESS OF WHETHER ORIGINAL DEFENSE WAS
            AWARE OF OR ADVISED IN ADVANCE OF THE POSSIBILITY OF DAMAGES OR SUCH CLAIMS. SOME STATES DO NOT
            ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU
            AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </p>
          <ol>
            <li>
              <strong>Dispute Resolution by Mandatory Binding Arbitration and Class Action Waiver</strong>
            </li>
          </ol>
          <p>
            PLEASE READ THIS ARBITRATION AND CLASS ACTION WAIVER PROVISION CAREFULLY. IT REQUIRES YOU TO
            ARBITRATE DISPUTES WITH ORIGINAL DEFENSE AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM
            US.
          </p>
          <p>
            EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE
            RESOLVED THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU
            ARE WAIVING THE RIGHT TO A TRIAL BY JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT,
            SUCH AS DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST. YOU AGREE THAT YOU
            MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF (LEAD OR OTHERWISE) OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE
            ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A
            REPRESENTATIVE OR CLASS PROCEEDING.&nbsp;
          </p>
          <p>
            THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
            HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT
            (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THE TERMS OF
            THESE CONDITIONS OF USE AS A COURT WOULD.
          </p>
          <ol>
            <li>
              <strong>General</strong>
            </li>
          </ol>
          <p>
            Arbitration is a manner of resolving a &ldquo;Claim&rdquo; without filing a lawsuit.
            &ldquo;Claim&rdquo; means any dispute between you, ORIGINAL DEFENSE, or any involved third-party
            relating to your account, your use of the Website, your relationship with ORIGINAL DEFENSE, these
            Terms, or the Privacy Policy. This includes any and all claims that relate in any way to your use
            or attempted use of the products, and any act or omission by ORIGINAL DEFENSE or any third-party
            related to your use or attempted use of the products. You, ORIGINAL DEFENSE, or any involved
            third-party may pursue a Claim. ORIGINAL DEFENSE agrees to final and binding confidential
            arbitration should it have any Claims against you. Likewise, you agree to final and binding
            confidential arbitration should you have any Claims against ORIGINAL DEFENSE. By agreeing to
            arbitrate, you waive the right to go to court and agree instead to submit any Claims to final and
            binding confidential arbitration. You further agree that all claims must be arbitrated on an
            individual basis and not on a class basis, only individual relief is available, and that claims of
            more than one customer cannot be arbitrated or consolidated with those of any other customer. This
            arbitration provision sets forth the terms and conditions of our agreement to final and binding
            confidential arbitration and is governed by and enforceable under the Federal Arbitration Act (the
            &ldquo;FAA&rdquo;), 9 U.S.C. &sect;&sect; 1-16, as amended.
          </p>
          <p>
            Notwithstanding anything to the contrary herein, (a) a representative action for public injunctive
            relief pursuant to California&rsquo;s Consumer Legal Remedies Act (Cal. Civ. Code &sect; 1750 et
            seq.), Unfair Competition Law (Cal. Bus. &amp; Prof. Code &sect; 17200 et seq.) and/or False
            Advertising Law (Cal. Bus. &amp; Prof. Code &sect; 17500 et seq.) must be arbitrated on a class
            basis, (b) in the event that the foregoing clause is deemed invalid or unenforceable, a
            representative action for public injunctive relief pursuant to California&rsquo;s Consumer Legal
            Remedies Act (Cal. Civ. Code &sect; 1750 et seq.), Unfair Competition Law (Cal. Bus. &amp; Prof.
            Code &sect; 17200 et seq.) and/or False Advertising Law (Cal. Bus. &amp; Prof. Code &sect; 17500
            et seq.) may be brought in the state or federal courts located in California on a class basis, and
            (c) any claims other than for public injunctive relief must be arbitrated on an individual,
            non-class basis as otherwise set forth in this Section.
          </p>
          <ol>
            <li>
              <strong>Exceptions</strong>
            </li>
          </ol>
          <p>
            Notwithstanding the foregoing, and as an exception to final and binding confidential arbitration,
            you and ORIGINAL DEFENSE both retain the right to pursue, in small claims court, any claim that is
            within that court&rsquo;s jurisdiction and proceeds on an individual (non-class) basis, including
            overdue account matters within the small claims court&rsquo;s jurisdiction. ORIGINAL DEFENSE will
            not demand arbitration in connection with any individual claim that you properly file and pursue
            in a small claims court, so long as the claim is and remains pending in that court.&nbsp;
          </p>
          <p>
            The following claims shall not be subject to final and binding arbitration and must be adjudicated
            only in the state or federal courts located in California: (i) an action by ORIGINAL DEFENSE
            relating to the infringement or validity of our proprietary rights, including without limitation,
            trademarks, service marks, trade dress, copyrights, trade secrets, or patents; or (ii) an action
            by ORIGINAL DEFENSE for temporary, preliminary, or permanent injunctive relief, whether
            prohibitive or mandatory, or other provisional relief, against you for breach or threatened breach
            of this Agreement. You expressly agree to refrain from bringing or joining any claims that are
            excluded from final and binding arbitration pursuant to this subsection &ldquo;b&rdquo; in any
            representative or class-wide capacity, including but not limited to bringing or joining any claims
            in any class action or any class-wide arbitration. Small claims matters may be filed in any small
            claims court with personal and subject matter jurisdiction over the parties. For all other matters
            excluded from final and binding arbitration by this subsection &ldquo;b,&rdquo; the parties
            consent to exclusive jurisdiction and venue in the state and federal courts located in California,
            and forever waive any challenge to said courts&rsquo; jurisdiction and venue.
          </p>
          <ol>
            <li>
              <strong> Required Pre-Dispute Procedures</strong>
            </li>
          </ol>
          <p>
            We acknowledge and agree that before initiating any Claim against the other, we agree to first
            contact the other with a written description of the dispute, which shall include all relevant
            documents and information, and the proposed resolution. You may send the written description of
            any dispute you have with us by U.S. Mail to ORIGINAL DEFENSE, LLC, Attn: Legal Department, 73
            Greentree Drive Num 60, Dover, DE 19904. ORIGINAL DEFENSE will contact you by letter at the
            billing address you provided to us or at the email address you provided to us. You agree to
            negotiate with ORIGINAL DEFENSE or its designated representative in good faith about your problem
            or dispute. If for some reason the dispute is not resolved within 30 days after receipt of the
            written dispute, we agree to the dispute resolution provisions below. Notwithstanding the
            foregoing or any other term of this arbitration agreement, we will have the right to seek
            injunctive or other equitable relief in state or federal court located in California to enforce
            these Terms or prevent an infringement of a third-party&rsquo;s rights or our intellectual
            property rights, as stated in subsection &ldquo;b&rdquo; above. You hereby expressly consent to,
            and forever waive any challenge to, the exclusive personal jurisdiction and venue of said courts
            in such actions.&nbsp;&nbsp;
          </p>
          <ol>
            <li>
              <strong>Commencing Arbitration</strong>
            </li>
          </ol>
          <p>
            You and ORIGINAL DEFENSE agree to commence any arbitration proceeding within one (1) year after
            the Claim arises (the one-year period includes the required pre-dispute procedures set forth
            above) and that any arbitration proceeding commenced after one (1) year shall be forever
            barred.&nbsp;
          </p>
          <ol>
            <li>
              <strong>Arbitration Location</strong>
            </li>
          </ol>
          <p>
            If the amount in controversy is $500 or less, then the arbitration may be conducted by telephone
            or by written submissions. Otherwise, the arbitration shall be conducted in California unless
            ORIGINAL DEFENSE otherwise agrees to arbitrate in another forum requested by you.&nbsp;
          </p>
          <ol>
            <li>
              <strong>Organization, Rules and the Arbitrator</strong>
            </li>
          </ol>
          <p>
            We each agree that any and all Claims other than those exempted under subsection &ldquo;b&rdquo;
            above shall be submitted to final and binding confidential arbitration before a single arbitrator
            of the American Arbitration Association (&ldquo;AAA&rdquo;). Either party may commence the
            arbitration process by submitting a written demand for arbitration with the AAA, and providing a
            copy to the other party, within the time period set forth in subsection &ldquo;d&rdquo; above. The
            arbitrator shall be selected by agreement of the parties or, if the parties cannot agree, chosen
            in accordance with Rules of the AAA. The arbitration will be conducted in accordance with the
            provisions of the AAA&rsquo;s Consumer Arbitration Rules, in effect at the time of submission of
            the demand for arbitration. The AAA&rsquo;s Rules are available at www.adr.org or by calling
            1-800-778-7879. The arbitrator shall have the exclusive and sole authority to resolve any dispute
            relating to the interpretation, construction, validity, applicability, or enforceability of these
            Terms, the Privacy Policy, and this arbitration provision. The arbitrator shall have the exclusive
            and sole authority to determine whether any dispute is arbitrable. The arbitrator shall have the
            exclusive and sole authority to determine whether this arbitration agreement can be enforced
            against a non-signatory to this agreement and whether a non-signatory to this agreement can
            enforce this provision against you or ORIGINAL DEFENSE.
          </p>
          <ol>
            <li>
              <strong>Fees</strong>
            </li>
          </ol>
          <p>
            Payment of all filing, administration and arbitrator fees will be governed by the AAA&rsquo;s
            Rules.
          </p>
          <ol>
            <li>
              <strong> Governing Law and Award</strong>
            </li>
          </ol>
          <p>
            The arbitrator shall follow the substantive law of the State of California without regard to its
            conflicts of laws principles. Any award rendered shall include a confidential written opinion and
            shall be final, subject to appeal under the FAA. Judgment on the award rendered by the arbitrator
            may be entered in any court of competent jurisdiction.&nbsp;
          </p>
          <ol>
            <li>
              <strong>Enforceability</strong>
            </li>
          </ol>
          <p>
            This provision survives termination of your account or relationship with ORIGINAL DEFENSE,
            bankruptcy, assignment, or transfer. If the class action waiver is deemed unenforceable (i.e.,
            unenforceability would allow arbitration to proceed as a class or representative action), then
            this entire arbitration provision shall be rendered null and void and shall not apply. If a
            portion of this arbitration provision (other than the class action waiver) is deemed
            unenforceable, the remaining portions of this arbitration provision shall remain in full force and
            effect.&nbsp;
          </p>
          <ol>
            <li>
              <strong>Miscellaneous</strong>
            </li>
          </ol>
          <p>
            Failure or any delay in enforcing this arbitration provision in connection with any particular
            Claim will not constitute a waiver of any rights to require arbitration at a later time or in
            connection with any other Claims except all Claims must be brought within the 1 year limitation
            period set forth above. This provision is the entire arbitration agreement between you and
            ORIGINAL DEFENSE and shall not be modified except in writing by ORIGINAL DEFENSE.&nbsp;
          </p>
          <ol>
            <li>
              <strong>Amendments</strong>
            </li>
          </ol>
          <p>
            ORIGINAL DEFENSE reserves the right to amend this arbitration provision at any time. Your
            continued use of the Website, purchase of a product on or through the Website, or use or attempted
            use of a ORIGINAL DEFENSE product, is affirmation of your consent to such changes. Should the
            changes to this arbitration provision be material, ORIGINAL DEFENSE will provide you notice and an
            opportunity to opt-out. Your continued use of the Website, purchase of a product on or through the
            Website, or use or attempted use of a ORIGINAL DEFENSE product, is affirmation of your consent to
            such material changes.
          </p>
          <p>
            YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL,
            AND CONFIDENTIAL ARBITRATION. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN
            THIRTY (30) DAYS FROM THE DATE THAT YOU PURCHASE, USE, OR ATTEMPT TO USE A PRODUCT PURCHASED ON OR
            THROUGH THE WEBSITE (WHICHEVER COMES FIRST) BY WRITING TO US AT ORIGINAL DEFENSE, LLC, ATTN: LEGAL
            DEPARTMENT, 1480 COLORADO BLVD., SUITE 150 LOS ANGELES, CA 90041.&nbsp;FOR YOUR OPT-OUT TO BE
            EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE IDENTIFYING ANY PRODUCT YOU PURCHASED, USED OR
            ATTEMPTED TO USE WITHIN THE 30 DAYS AND THE DATE YOU FIRST PURCHASED, USED OR ATTEMPTED TO USE THE
            PRODUCT. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS
            PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING ARBITRATION AS SET FORTH IN THIS
            AGREEMENT.
          </p>
          <ol>
            <li>
              <strong>INDEMNIFICATION</strong>
            </li>
          </ol>
          <p>
            To the fullest extent permitted by law, you agree to indemnify, defend, and hold harmless ORIGINAL
            DEFENSE, its parent, subsidiaries, predecessors, successors and affiliates, and their respective
            partners, officers, directors, agents, representatives, contractors, licensors, service providers,
            subcontractors, suppliers, interns, and employees, from and against any and all claims, actions,
            losses, liabilities, damages, expenses, demands and costs of any kind, including, but not limited
            to, reasonable attorneys&rsquo; fees, arising out of, resulting from, or in any way connected with
            or related to (1) your breach of these Terms, the documents they incorporate by reference, or the
            Agreement; (2) your breach of any representations or warranties in this Agreement; or (3) your
            violation of any law or the rights of a third-party.
          </p>
          <ol>
            <li>
              <strong>THIRD-PARTY WEBSITES AND LINKS</strong>
            </li>
          </ol>
          <p>
            Our Website may include materials from third-parties or links to third-party websites. We are not
            liable for any third-party materials or websites. Please review carefully the third-party&rsquo;s
            policies and practices and make sure you understand them before you engage in any transaction.
            Complaints, claims, concerns, or questions regarding third-party products should be directed to
            the third-party.&nbsp;
          </p>
          <ol>
            <li>
              <strong>TESTIMONIALS, REVIEWS, AND OTHER SUBMISSIONS&nbsp;</strong>
            </li>
          </ol>
          <p>
            ORIGINAL DEFENSE may use testimonials and/or product reviews in whole or in part together with the
            name and state/country of the person submitting it. Testimonials may be used for any form of
            activity relating to ORIGINAL DEFENSE&rsquo;s products, in printed and online media, as ORIGINAL
            DEFENSE determines in its absolute discretion. Testimonials represent the unique experience of the
            customers submitting the testimonial, and do not necessarily reflect the experience that you may
            have using our products. &nbsp;
          </p>
          <p>
            Anything that you submit or post to the Website and/or provide us, including without limitation,
            photographs, testimonials, ideas, know-how, techniques, questions, reviews, comments, and
            suggestions (collectively, &ldquo;Submissions&rdquo;) is and will be treated as non-confidential
            and nonproprietary, and we shall have the royalty-free, worldwide, perpetual, irrevocable and
            transferable right to use, copy, distribute, display, publish, perform, sell, lease, transmit,
            adapt, and create derivative works from such Submissions by any means and in any form, and to
            translate, modify, reverse-engineer, disassemble, or decompile such Submissions. You represent and
            warrant that you are the owner or have sufficient rights to share the Submissions with us. All
            Submissions shall automatically become our sole and exclusive property and shall not be returned
            to you.
          </p>
          <p>
            Additionally, ORIGINAL DEFENSE reserves the right to correct grammatical and typing errors, to
            shorten testimonials prior to publication or use, and to review all testimonials prior to
            publication or use. ORIGINAL DEFENSE shall be under no obligation to use any, or any part of, any
            testimonial or product review submitted. If you submit a testimonial, you are confirming that you
            have read, understood and agree to these Terms. If you disagree with any part of these Terms, do
            not submit a testimonial.
          </p>
          <ol>
            <li>
              <strong> DIGITAL MILLENNIUM COPYRIGHT ACT NOTICE</strong>
            </li>
          </ol>
          <p>
            This Website maintains specific contact information provided below, including an e-mail address,
            for notifications of claimed infringement regarding materials posted to this Website. All notices
            should be addressed to the contact person specified below (our agent for notice of claimed
            infringement):
          </p>
          <p>Notification of Claimed Infringement:&nbsp;</p>
          <p>ORIGINAL DEFENSE, LLC&nbsp;</p>
          <p>Attn: DMCA/Copyright Agent</p>
          <p>73 Greentree Drive Num 60</p>
          <p>Dover, DE 19904&nbsp;</p>
          <p>Tel: 1-800-756-7530</p>
          <p>
            Email Address: <a href='mailto:support@originaldefense.com'>support@originaldefense.com</a>&nbsp;
          </p>
          <p>
            You may contact our agent for notice of claimed infringement specified above with complaints
            regarding allegedly infringing posted material and we will investigate those complaints. If the
            posted material is believed in good faith by us to violate any applicable law, we will remove or
            disable access to any such material, and we will notify the posting party that the material has
            been blocked or removed.&nbsp;
          </p>
          <p>
            In notifying us of alleged copyright infringement, the Digital Millennium Copyright Act requires
            that you include the following information: (i) description of the copyrighted work that is the
            subject of claimed infringement; (ii) description of the infringing material and information
            sufficient to permit us to locate the alleged material; (iii) contact information for you,
            including your address, telephone number and/or e-mail address; (iv) a statement by you that you
            have a good faith belief that the material in the manner complained of is not authorized by the
            copyright owner, or its agent, or by the operation of any law; (v) a statement by you, signed
            under penalty of perjury, that the information in the notification is accurate and that you have
            the authority to enforce the copyrights that are claimed to be infringed; and (vi) a physical or
            electronic signature of the copyright owner or a person authorized to act on the copyright
            owner&rsquo;s behalf. Failure to include all of the above-listed information may result in the
            delay of the processing of your complaint.
          </p>
          <ol>
            <li>
              <strong>ELECTRONIC COMMUNICATIONS&nbsp;</strong>
            </li>
          </ol>
          <p>
            You agree that we may communicate electronically with you and that such communications, as well as
            notices, disclosures, agreements, and other communications that we provide to you electronically,
            are equivalent to communications in writing and shall have the same force and effect as if they
            were in writing and signed by the party sending the communication.&nbsp;
          </p>
          <ol>
            <li>
              <strong>ASSIGNMENT&nbsp;</strong>
            </li>
          </ol>
          <p>
            You may not assign any of your rights under these Terms, and any such attempt will be null and
            void. ORIGINAL DEFENSE and its affiliates may, in their individual discretion, transfer, without
            further consent or notification, all contractual rights and obligations pursuant to these Terms if
            some or all of ORIGINAL DEFENSE&rsquo;s business is transferred to another entity by way of
            merger, sale of its assets or otherwise.&nbsp;
          </p>
          <ol>
            <li>
              <strong>NO WAIVER&nbsp;</strong>
            </li>
          </ol>
          <p>
            No waiver by ORIGINAL DEFENSE of any term or condition set forth in these Terms shall be deemed a
            further or continuing waiver of such term or condition or a waiver of any other term or condition,
            and any failure by ORIGINAL DEFENSE to assert a right or provision under these Terms shall not
            constitute a waiver of such right or provision.
          </p>
          <ol>
            <li>
              <strong>NO AGENCY RELATIONSHIP&nbsp;</strong>
            </li>
          </ol>
          <p>
            No joint venture, partnership, employment, or agency relationship exists between you and us as a
            result of you receiving a product from us or from using this Website.
          </p>
          <ol>
            <li>
              <strong>SEVERABILITY&nbsp;</strong>
            </li>
          </ol>
          <p>
            In the event that any provision of these Terms is determined to be unlawful, void or
            unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be severed from these Terms. Such
            determination shall not affect the validity and enforceability of any other remaining provisions.
          </p>
          <ol>
            <li>
              <strong>TERMINATION</strong>
            </li>
          </ol>
          <p>
            In the event that we terminate this Agreement, Sections 2-5, 13-17, 19-25, 27, as well as any
            representations, warranties, and other obligations made or taken by you, shall survive the
            termination of this Agreement.
          </p>
          <ol>
            <li>
              <strong>ENTIRE AGREEMENT&nbsp;</strong>
            </li>
          </ol>
          <p>
            These Terms, the Agreement, and any policies or operating rules posted by us on the Website or in
            respect to the Website constitutes the entire agreement and understanding between you and ORIGINAL
            DEFENSE, and supersedes and replaces any prior or contemporaneous agreements. Any ambiguities in
            the interpretation of these Terms or the Agreement shall not be construed against the drafting
            party.
          </p>
          <ol>
            <li>
              <strong>QUESTIONS OR ADDITIONAL INFORMATION&nbsp;</strong>
            </li>
          </ol>
          <p>
            If you purchased a product or service through the Website, please contact Customer Support by
            phone or email.&nbsp;
          </p>
          <p>ORIGINAL DEFENSE, LLC&nbsp;</p>
          <p>73 Greentree Drive Num 60</p>
          <p>Dover, DE 19904&nbsp;</p>
          <p>Tel: 1-800-756-7530</p>
          <p>
            Email Address: <a href='mailto:support@originaldefense.com'>support@originaldefense.com</a>&nbsp;
          </p>
          <p> </p>
          <p>Return to Top</p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      <div className='custom-modal terms-modal'>
        <button onClick={closeModal} className='custom-modal__close'>
          <i className='far fa-times'></i>
        </button>
        <p>
          <strong>TERMS AND CONDITIONS OF USE AND SALE</strong>
        </p>
        <p>
          <strong>OVERVIEW</strong>
        </p>
        <p>
          <strong>
            IMPORTANT – PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF USE AND CONDITIONS OF SALE BEFORE
            ACCESSING, USING, OR PLACING AN ORDER OVER {company.website}. THESE TERMS CONTAIN DISCLAIMERS OF
            WARRANTIES AND LIMITATIONS OF LIABILITIES (SEE SECTIONS 14 AND 15). THESE TERMS FORM AN ESSENTIAL
            BASIS OF OUR AGREEMENT. PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.
          </strong>
        </p>
        <p>
          Your use of {company.website}, including any subdomains thereof, affiliated websites, and mobile
          applications (collectively, the “Website”), which are owned and maintained by {company.name} (“
          {company.name},” “we,” “our,” “us”), are governed by the policies, terms, and conditions set forth
          below. Please read them carefully. We offer the Website, including all information, tools, products
          and services available from the Website to you, the user, conditioned upon your acceptance of all
          terms, conditions, policies and notices stated here. By accessing, using, or placing an order over
          the Website, you agree to the terms set forth herein. If you do not agree to these terms and
          conditions in their entirety, you are not authorized to use the Website in any manner or form
          whatsoever.
        </p>
        <p>
          <strong>
            THIS AGREEMENT CONTAINS ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A
            COURT HEARING, RIGHT TO A JURY TRIAL AND RIGHT TO PARTICIPATE IN A CLASS ACTION.  ARBITRATION IS
            MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW IN SECTION
            16 OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 16
            BELOW WHICH DESCRIBES YOUR RIGHT TO OPT-OUT.
          </strong>
        </p>
        <p>
          You can review the most current version of the Terms at any time on this page ({company.website}).
          We reserve the right to update, change, or replace any part of these Terms by posting updates and/or
          changes to our Website. It is your responsibility to check this page periodically for changes. YOUR
          CONTINUED USE OF OR ACCESS TO THE WEBSITE FOLLOWING THE POSTING OF ANY CHANGES CONSTITUTES BINDING
          ACCEPTANCE OF THOSE CHANGES.
        </p>
        <p>
          <strong>TABLE OF CONTENTS</strong>
        </p>
        <p>OVERVIEW</p>
        <ol>
          <li> WEBSITE USE</li>
          <li> PRIVACY &amp; SECURITY DISCLOSURE</li>
          <li> GENERAL CONDITIONS AND WEBSITE USER CONDUCT RESTRICTIONS</li>
          <li> PRODUCTS SOLD FOR PERSONAL USE ONLY AND AS PART OF A HEALTHY LIFESTYLE</li>
          <li> NO MEDICAL ADVICE, AND ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</li>
          <li> MODIFICATIONS TO THE WEBSITE AND PRICES</li>
          <li> PAYMENT</li>
          <li> ORDER PLACEMENT AND ACCEPTANCE</li>
          <li> SUBSCRIPTIONS</li>
          <li> SHIPPING</li>
          <li> DELIVERY CONFIRMATION</li>
          <li> RETURNS AND REFUNDS</li>
          <li> SOCIAL MEDIA</li>
          <li> DISCLAIMER OF WARRANTIES</li>
          <li> DISCLAIMER OF LIABILITIES</li>
          <li> DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</li>
          <li> INDEMNIFICATION</li>
          <li> THIRD-PARTY WEBSITES AND LINKS</li>
          <li> TESTIMONIALS, REVIEWS, AND OTHER SUBMISSIONS</li>
          <li> DIGITAL MILLENNIUM COPYRIGHT ACT NOTICE</li>
          <li> ELECTRONIC COMMUNICATIONS</li>
          <li> ASSIGNMENT</li>
          <li> NO WAIVER</li>
          <li> NO AGENCY RELATIONSHIP</li>
          <li> SEVERABILITY</li>
          <li> TERMINATION</li>
          <li> ENTIRE AGREEMENT</li>
          <li> QUESTIONS OR ADDITIONAL INFORMATION</li>
        </ol>

        <p>
          <strong>TERMS AND CONDITIONS OF USE AND SALE</strong>
        </p>

        <ol>
          <li>
            <strong>WEBSITE USE</strong>
          </li>
        </ol>
        <p>
          By using the Website and agreeing to these Terms, you represent that you are at least the age of
          majority in your state or province of residence. If you use the Website, you are affirming that you
          have the legal capacity to enter into a binding contract with us, and have read this Agreement and
          understand and agree to its terms.
        </p>
        <ol start='2'>
          <li>
            <strong>PRIVACY &amp; SECURITY DISCLOSURE</strong>
          </li>
        </ol>
        <p>
          Our privacy policy may be viewed at{' '}
          <button
            className='link'
            onClick={() => {
              closeModal();
              onPrivacyClick();
            }}
          >
            Privacy Policy
          </button>
          .  The Privacy Policy is hereby incorporated into these Terms by reference and constitute a part of
          these Terms.  {company.name} reserves the right to modify the privacy policy at its sole discretion.
        </p>
        <ol start='3'>
          <li>
            <strong>GENERAL CONDITIONS AND WEBSITE USER CONDUCT RESTRICTIONS</strong>
          </li>
        </ol>
        <p>
          All aspects of our Website are protected by U.S. and international copyright, trademark, and other
          intellectual property laws. You do not acquire any ownership or other rights by downloading or using
          the Website or any material on it.
        </p>
        <p>
          You agree not to use or attempt to use the Website or any products or services in any unlawful
          manner. You further agree not to commit any unlawful act or attempt to commit any unlawful act on or
          through the Website including, but not limited to: (1) hacking and other digital or physical attacks
          on the Website; (2) publishing vulgar, obscene, or defamatory material; or (3) any other unlawful
          act.
        </p>
        <ol start='4'>
          <li>
            <strong>PRODUCTS SOLD FOR PERSONAL USE ONLY AND AS PART OF A HEALTHY LIFESTYLE</strong>
          </li>
        </ol>
        <p>
          {company.name}’s products and services are for adults only. By using our website and placing an
          order, you represent and warrant that you are at least 18 years old or of the age of majority in the
          jurisdiction where you reside.  You further agree that any products or services you purchase from{' '}
          {company.name} and/or our Website will be used for your personal, non-commercial use. You agree that
          you will not resell, re-distribute, modify, or export any product that you order from the Website.
        </p>
        <p>
          {company.name} does not make any guarantee that you will accomplish your health, wellness, and/or
          beauty goals.  All products and services are to be used as a part of a healthy lifestyle that
          includes appropriate nutrition, exercise, and self-care. The results reported, if any, may not
          necessarily occur in all individuals. Your results may vary depending upon a variety of factors
          unique to you, such as your age, health, genetics, and starting point.
        </p>
        <ol start='5'>
          <li>
            <strong>NO MEDICAL ADVICE, AND ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</strong>
          </li>
        </ol>
        <p>
          The material on the Website is provided for general informational purposes only and is not intended
          as medical advice, or as a substitute for the medical advice of a physician.  The statements made on
          this Website have not been evaluated by the Food and Drug Administration and are not intended to
          diagnose, treat, cure or prevent any disease. 
        </p>
        <p>
          You should consult your physician before beginning any program, exercise, body awareness techniques,
          or meditation; changing your diet; taking any nutritional or herbal supplement; or using any
          information provided by {company.name}.
        </p>
        <p>
          While we endeavor to provide accurate and current information on our Website, there may be
          information on our Website that contains typographical errors, inaccuracies, or omissions that may
          relate to product descriptions, pricing, promotions, offers, product shipping charges, transit
          times, and availability. We reserve the right to correct any errors, inaccuracies, or omissions, and
          to change or update information or cancel orders if any information on the Website or on any related
          website is inaccurate at any time without prior notice (including after you have submitted your
          order).
        </p>
        <p>
          This Website may contain certain historical information. Historical information, necessarily, is not
          current and is provided for your reference only. We reserve the right to modify the contents of this
          Website at any time, but we have no obligation to update any information on our Website. You agree
          that it is your responsibility to monitor changes to our Website.
        </p>
        <ol start='6'>
          <li>
            <strong>MODIFICATIONS TO THE WEBSITE AND PRICES</strong>
          </li>
        </ol>
        <p>
          We reserve the right at any time to modify or discontinue access to the Website (or any part or
          content thereof) without notice at any time.  We shall not be liable to you or to any third-party
          for any modification, suspension, or discontinuance of access to the Website.  Certain products or
          services may be available exclusively online through the Website.  These products or services may
          have limited quantities and are subject to return or exchange only according to our Shipping and
          Returns Policies.
        </p>
        <p>
          We reserve the right to limit the sales of our products to any person, geographic region, or
          jurisdiction. We may exercise this right on a case-by-case basis. All descriptions of products or
          product pricing are subject to change at any time without notice, at our sole discretion.  Any offer
          for any product or service made on this Website is void where prohibited.
        </p>
        <ol start='7'>
          <li>
            <strong>PAYMENT</strong>
          </li>
        </ol>
        <p>
          All charges are in U.S. Dollars. We accept major credit and debit cards from Visa, MasterCard,
          American Express, and Discover.
        </p>
        <p>When placing an order online, you will need:</p>
        <ol>
          <li>The address the card’s statement is sent to (billing address);</li>
          <li>The card number and expiration date; and</li>
          <li>The 3 or 4 digit code found only on the card (CVV2 code).</li>
        </ol>
        <p>
          By submitting credit card information or other payment information to us, you represent and agree
          that: (i) you are fully entitled to use that card or account; (ii) all payment information provided
          is complete and accurate; (iii) you will be responsible for any credit card fees; and (iv) that
          sufficient funds exist to pay us the amount(s) due.
        </p>
        <p>
          We and our third-party payment service providers may request, and we may receive, updated credit
          card information from your credit card issuer, such as updated card numbers and expiration date
          information when your credit card has expired. If such updated information is provided to us and our
          third-party payment service providers, we will update your account information accordingly.  Your
          credit card issuer may give you the right to opt-out of providing vendors and third-party payment
          service providers with your updated credit card information. If you wish to opt-out of your credit
          card’s updating service, you should contact your credit card issuer.
        </p>
        <p>
          We are not responsible for any fees or charges that your bank or credit card issuer may apply. If
          your bank or credit card issuer reverses a charge to your credit card, we may bill you directly and
          seek payment by another method including a mailed statement. 
        </p>
        <p>
          We strive to provide the best products, value, and customer experiences. With the recent increase in
          wages, benefits, healthcare laws, taxes, inflation, supply chain costs, and other obligations, we
          will include an 8% Service Fee. We have always put our customers and employees first and we will
          continue to do so, and appreciate your support of our policy. If you have any questions or concerns,
          please do not hesitate to contact our customer service department.
        </p>
        <p>
          By accepting this terms and conditions you hereby acknowledge and agree that we may sell and assign
          our right, title and interests in and to your payment obligation to FlexFactor Operations Inc. or
          its affiliate FlexCorp Inc. (the "Factor"). Note, you will not be impacted by such assignment. Any
          such assignment will be governed by the Factor's End User Agreement (available 
          <a target='_blank' href='https://www.flex-charge.com/flexcharge-end-user-terms-of-service'>
            here
          </a>
          ), and Privacy Policy (available 
          <a target='_blank' href='https://www.flex-charge.com/privacy-policy'>
            here
          </a>
          ). If you have any questions regarding such assignment, you may contact the Factor
          at: customersupport@flexfactor.io.
        </p>
        <ol start='8'>
          <li>
            <strong>ORDER PLACEMENT AND ACCEPTANCE</strong>
          </li>
        </ol>
        <p>
          Your electronic order confirmation, or any form of confirmation, does not signify our acceptance of
          your order. We reserve the right to accept or deny shipment to anyone for any reason. In the event
          we deny your order, you will receive a refund to your original form of payment. You understand and
          agree that we will not be liable for any losses or damages that may result from our refusal to
          provide you any service or product. We reserve the right to require additional information before
          processing any order.
        </p>
        <ol start='9'>
          <li>
            <strong>SUBSCRIPTIONS</strong>
          </li>
        </ol>
        <p>
          If you go to the Website to purchase a product, you may have the option of buying a product one
          time, or purchasing a subscription. If you purchase a subscription, it is automatically billed every
          thirty (30) days to the credit card you provided when you purchased the subscription and mailed to
          you, until you cancel the subscription. We may use an account updater to automatically update your
          credit card information in the event it changes, in which case, your renewal will be billed to the
          updated account information. If you wish to cancel your subscription, you may do so at any time,
          however, you must cancel your subscription twenty-four (24) hours prior to your next shipment going
          out in order to not receive your next scheduled order. To cancel your subscription, simply call
          Customer Support at {company.phone} or email us at {company.email}.
        </p>
        <ol start='10'>
          <li>
            <strong>SHIPPING</strong>
          </li>
        </ol>
        <p>
          {company.name} ships to addresses located in the United States and internationally via USPS.  Orders
          generally ship within 24-48 business hours of the time of placement.  Domestic orders typically
          arrive within 3 days of shipment.  Delivery time for international orders varies based on location. 
          Please visit our Shipping Policies webpage for additional terms that may apply to your order.
        </p>
        <p>
          Accurate shipping address and phone number are required. We are not responsible for late
          shipments/missing shipments if you enter incorrect shipping address information. If you discover
          that you have made a mistake with your order after it has been submitted, please contact Customer
          Support by phone or email ({company.phone}/{company.email}) immediately. You must contact us as soon
          as possible in order to modify or cancel your pending order. However, we frequently ship the same
          day that you order, so we cannot guarantee that we will be able to amend your order in accordance
          with your instructions. 
        </p>
        <ol start='11'>
          <li>
            <strong>DELIVERY CONFIRMATION</strong>
          </li>
        </ol>
        <p>
          Because many instances may occur at your delivery address that are beyond our control, you agree
          that any delivery confirmation provided by the carrier is deemed sufficient proof of delivery to the
          card holder, even without a signature.
        </p>
        <ol start='12'>
          <li>
            <strong>RETURNS AND REFUNDS</strong>
          </li>
        </ol>
        <p>
          {company.name} wants you to be beyond satisfied with your experience with us so we have made
          returning or exchanging products easy. If you are unsatisfied with our product, or if it arrives
          damaged, you may return or exchange the unused portion 30 days from the date that you placed your
          order (the billing date) for a full refund according to the following terms.
        </p>
        <p>
          To obtain a full refund, you must return any empty or unused portion of your order, your return must
          be approved, and the return must be received within 30 days of the date that you placed your order
          (the billing date).
        </p>
        <p>The following terms apply for all returned items:</p>
        <ul>
          <li>
            {' '}
            To initiate a refund or exchange, simply email us at {company.email}. You will be given a Return
            Merchandise Authorization (RMA) number and details for shipping.
          </li>
          <li> We cannot process or refund packages marked “Return to Sender.”</li>
          <li>
            {' '}
            Refunds will be issued to the same credit card or method of payment that was charged when ordering
            the product.
          </li>
          <li>
            {' '}
            To ensure a refund is processed for you, please send returns to the address provided along with
            your RMA number. The RMA number must be clearly written on the package that you are sending back.
            Our shipping department is NOT allowed to accept any packages without an RMA number.
          </li>
        </ul>
        <p>Returned products must be sent to the following address:</p>
        <p>FDC Returns</p>
        <p>P.O. Box 61553</p>
        <p>Savannah, GA 31420</p>
        <p>
          We are not responsible for lost or stolen items. We recommend all returned items to be sent using
          some type of delivery confirmation system to ensure proper delivery.
        </p>
        <p>
          <b>Customers are responsible for the cost of return shipping.</b>
        </p>
        <p>
          After the shipping department receives your return, it generally takes 5-10 business days or sooner
          to process your refund. Once a return is processed, it usually takes 30 business days for this
          return to be posted to your account, depending on your financial institution.
        </p>

        <ol start='13'>
          <li>
            <strong>SOCIAL MEDIA</strong>
          </li>
        </ol>
        <p>
          This section applies to everyone who interacts with our social media presence, including comment
          sections, feeds, and other elements of social media presence viewable on Facebook, Instagram,
          YouTube, Pinterest, Twitter, Google+, LinkedIn, or any of the many other available external
          third-party social media platforms we may utilize (“Social Media Presence”).
        </p>
        <p>
          Social media platforms are places of public information exchange, and you should have no expectation
          of privacy when using them. Specifically, neither these Terms nor our Privacy Statement apply to our
          Social Media Presence. The sites and platforms that host our Social Media Presence are not
          controlled by us and therefore have their own privacy policies and terms of use. The comments and
          opinions expressed by users on social media are theirs alone and do not reflect the opinions of{' '}
          {company.name}. Comments that some would consider inappropriate or offensive may appear on our
          Social Media Presence and may remain there until they have been identified by us or called to our
          attention and we are able to work through the necessary procedures and technical processes to have
          them removed. If you see an offensive or inappropriate post or comment on our Social Media Presence,
          you should report it to the operator of the applicable site or platform using the procedures they
          have established for that purpose.
        </p>
        <ol start='14'>
          <li>
            <strong>DISCLAIMER OF WARRANTIES</strong>
          </li>
        </ol>
        <p>
          EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THIS WEBSITE AND ALL PRODUCTS PROVIDED ARE
          PROVIDED ON AN “AS IS”, “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY
          OR FITNESS FOR A PARTICULAR PURPOSE. WE MAKE NO, AND EXPRESSLY DISCLAIM ANY AND ALL, REPRESENTATIONS
          AND WARRANTIES AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY
          AND/OR COMPLETENESS OF ANY INFORMATION ON THIS WEBSITE. WE DO NOT REPRESENT OR WARRANT, AND
          EXPRESSLY DISCLAIM THAT: (A) OUR PRODUCTS WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (B) THE
          QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
          THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR (C) THE WEBSITE OR THE SERVER(S)
          THAT MAKE THE WEBSITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALL CONDITIONS,
          REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NONINFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW.
        </p>
        <ol start='15'>
          <li>
            <strong>DISCLAIMER OF LIABILITIES</strong>
          </li>
        </ol>
        <p>
          EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, IN NO EVENT SHALL {company.name} OR ANY OF
          ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS, TELECOMMUNICATIONS
          PROVIDERS, AND/OR AGENTS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL,
          PUNITIVE, OR ANY OTHER DAMAGES, FEES, COSTS OR CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT, THE
          PRIVACY POLICY, THE PRODUCTS, OR YOUR OR A THIRD-PARTY’S USE OR ATTEMPTED USE OF THE WEBSITE OR ANY
          PRODUCT, REGARDLESS OF WHETHER {company.name} HAS HAD NOTICE OF THE POSSIBILITY OF SUCH DAMAGES,
          FEES, COSTS, OR CLAIMS. THIS INCLUDES, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF
          DATA, LOSS OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR PRODUCTS, OR ANY OTHER
          INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR OTHER DAMAGES. THIS APPLIES REGARDLESS OF
          THE MANNER IN WHICH DAMAGES ARE ALLEGEDLY CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH
          OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, OR OTHERWISE. IF,
          NOTWITHSTANDING THE LIMITATIONS OF LIABILITY SET FORTH ABOVE, {company.name} IS FOUND LIABLE UNDER
          ANY THEORY, {company.name}’S LIABILITY AND YOUR EXCLUSIVE REMEDY WILL BE LIMITED TO USD $500.00.
          THIS LIMITATION OF LIABILITY SHALL APPLY FOR ALL CLAIMS, REGARDLESS OF WHETHER {company.name} WAS
          AWARE OF OR ADVISED IN ADVANCE OF THE POSSIBILITY OF DAMAGES OR SUCH CLAIMS. SOME STATES DO NOT
          ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU AND
          YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <ol start='16'>
          <li>
            <strong>DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</strong>
          </li>
        </ol>
        <p>
          PLEASE READ THIS ARBITRATION AND CLASS ACTION WAIVER PROVISION CAREFULLY.  IT REQUIRES YOU TO
          ARBITRATE DISPUTES WITH {company.name}
          AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
        </p>
        <p>
          EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE
          RESOLVED THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION.  YOU ACKNOWLEDGE AND AGREE THAT YOU ARE
          WAIVING THE RIGHT TO A TRIAL BY JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS
          DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST. YOU AGREE THAT YOU MAY ONLY
          BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF (LEAD OR OTHERWISE) OR CLASS MEMBER
          IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT
          CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS
          PROCEEDING.
        </p>
        <p>
          THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
          HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT
          (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THE TERMS OF
          THESE CONDITIONS OF USE AS A COURT WOULD.
        </p>
        <ol start='1' className='letter-list'>
          <li>
            <strong>General</strong>
          </li>
        </ol>
        <p>
          Arbitration is a manner of resolving a “Claim” without filing a lawsuit. “Claim” means any dispute
          between you, {company.name}, or any involved third-party relating to your account, your use of the
          Website, your relationship with {company.name}, these Terms, or the Privacy Policy. This includes
          any and all claims that relate in any way to your use or attempted use of the products, and any act
          or omission by {company.name} or any third-party related to your use or attempted use of the
          products. You, {company.name}, or any involved third-party may pursue a Claim. {company.name} agrees
          to final and binding confidential arbitration should it have any Claims against you. Likewise, you
          agree to final and binding confidential arbitration should you have any Claims against{' '}
          {company.name}.  By agreeing to arbitrate, you waive the right to go to court and agree instead to
          submit any Claims to final and binding confidential arbitration. You further agree that all claims
          must be arbitrated on an individual basis and not on a class basis, only individual relief is
          available, and that claims of more than one customer cannot be arbitrated or consolidated with those
          of any other customer. This arbitration provision sets forth the terms and conditions of our
          agreement to final and binding confidential arbitration and is governed by and enforceable under the
          Federal Arbitration Act (the “FAA”), 9 U.S.C. §§ 1-16, as amended.
        </p>
        <p>
          Notwithstanding anything to the contrary herein, (a) a representative action for public injunctive
          relief pursuant to California’s Consumer Legal Remedies Act (Cal. Civ. Code § 1750 et seq.), Unfair
          Competition Law (Cal. Bus. &amp; Prof. Code § 17200 et seq.) and/or False Advertising Law (Cal. Bus.
          &amp; Prof. Code § 17500 et seq.) must be arbitrated on a class basis, (b) in the event that the
          foregoing clause is deemed invalid or unenforceable, a representative action for public injunctive
          relief pursuant to California’s Consumer Legal Remedies Act (Cal. Civ. Code § 1750 et seq.), Unfair
          Competition Law (Cal. Bus. &amp; Prof. Code § 17200 et seq.) and/or False Advertising Law (Cal. Bus.
          &amp; Prof. Code § 17500 et seq.) may be brought in the state or federal courts located in
          California on a class basis, and (c) any claims other than for public injunctive relief must be
          arbitrated on an individual, non-class basis as otherwise set forth in this Section.
        </p>
        <ol start='2' className='letter-list'>
          <li>
            <strong>Exceptions</strong>
          </li>
        </ol>
        <p>
          Notwithstanding the foregoing, and as an exception to final and binding confidential arbitration,
          you and {company.name} both retain the right to pursue, in small claims court, any claim that is
          within that court’s jurisdiction and proceeds on an individual (non-class) basis, including overdue
          account matters within the small claims court’s jurisdiction. {company.name} will not demand
          arbitration in connection with any individual claim that you properly file and pursue in a small
          claims court, so long as the claim is and remains pending in that court.
        </p>
        <p>
          The following claims shall not be subject to final and binding arbitration and must be adjudicated
          only in the state or federal courts located in California: (i) an action by {company.name} relating
          to the infringement or validity of our proprietary rights, including without limitation, trademarks,
          service marks, trade dress, copyrights, trade secrets, or patents; or (ii) an action by{' '}
          {company.name} for temporary, preliminary, or permanent injunctive relief, whether prohibitive or
          mandatory, or other provisional relief, against you for breach or threatened breach of this
          Agreement. You expressly agree to refrain from bringing or joining any claims that are excluded from
          final and binding arbitration pursuant to this subsection “b” in any representative or class-wide
          capacity, including but not limited to bringing or joining any claims in any class action or any
          class-wide arbitration. Small claims matters may be filed in any small claims court with personal
          and subject matter jurisdiction over the parties. For all other matters excluded from final and
          binding arbitration by this subsection “b,” the parties consent to exclusive jurisdiction and venue
          in the state and federal courts located in California, and forever waive any challenge to said
          courts’ jurisdiction and venue.
        </p>
        <ol start='3' className='letter-list'>
          <li>
            <strong> Required Pre-Dispute Procedures</strong>
          </li>
        </ol>
        <p>
          We acknowledge and agree that before initiating any Claim against the other, we agree to first
          contact the other with a written description of the dispute, which shall include all relevant
          documents and information, and the proposed resolution. You may send the written description of any
          dispute you have with us by U.S. Mail to {company.name}, LLC, Attn: Legal Department,{' '}
          {company.address}.  {company.name} will contact you by letter at the billing address you provided to
          us or at the email address you provided to us. You agree to negotiate with {company.name} or its
          designated representative in good faith about your problem or dispute. If for some reason the
          dispute is not resolved within 60 days after receipt of the written dispute, we agree to the dispute
          resolution provisions below. Notwithstanding the foregoing or any other term of this arbitration
          agreement, we will have the right to seek injunctive or other equitable relief in state or federal
          court located in California to enforce these Terms or prevent an infringement of a third-party’s
          rights or our intellectual property rights, as stated in subsection “b” above. You hereby expressly
          consent to, and forever waive any challenge to, the exclusive personal jurisdiction and venue of
          said courts in such actions.
        </p>
        <ol start='4' className='letter-list'>
          <li>
            <strong>Commencing Arbitration</strong>
          </li>
        </ol>
        <p>
          You and {company.name} agree to commence any arbitration proceeding within 1 year after the Claim
          arises (the 1 year period includes the required pre-dispute procedures set forth above) and that any
          arbitration proceeding commenced after 1 year shall be forever barred.
        </p>
        <ol start='5' className='letter-list'>
          <li>
            <strong>Arbitration Location</strong>
          </li>
        </ol>
        <p>
          If the amount in controversy is $500 or less, then the arbitration may be conducted by telephone or
          by written submissions. Otherwise, the arbitration shall be conducted in California unless{' '}
          {company.name}
          otherwise agrees to arbitrate in another forum requested by you.
        </p>
        <ol start='6' className='letter-list'>
          <li>
            <strong>Organization, Rules and the Arbitrator</strong>
          </li>
        </ol>
        <p>
          We each agree that any and all Claims other than those exempted under subsection “b” above shall be
          submitted to final and binding confidential arbitration before a single arbitrator of the American
          Arbitration Association (“AAA”). Either party may commence the arbitration process by submitting a
          written demand for arbitration with the AAA, and providing a copy to the other party, within the
          time period set forth in subsection “d” above. The arbitrator shall be selected by agreement of the
          parties or, if the parties cannot agree, chosen in accordance with Rules of the AAA. The arbitration
          will be conducted in accordance with the provisions of the AAA’s Consumer Arbitration Rules, in
          effect at the time of submission of the demand for arbitration. The AAA’S Rules are available at
          www.adr.org or by calling 1-800-778-7879. The arbitrator shall have the exclusive and sole authority
          to resolve any dispute relating to the interpretation, construction, validity, applicability, or
          enforceability of these Terms, the Privacy Policy, and this arbitration provision. The arbitrator
          shall have the exclusive and sole authority to determine whether any dispute is arbitrable. The
          arbitrator shall have the exclusive and sole authority to determine whether this arbitration
          agreement can be enforced against a non-signatory to this agreement and whether a non-signatory to
          this agreement can enforce this provision against you or {company.name}.
        </p>
        <ol start='7' className='letter-list'>
          <li>
            <strong>Fees</strong>
          </li>
        </ol>
        <p>Payment of all filing, administration and arbitrator fees will be governed by the AAA’s Rules.</p>
        <ol start='8' className='letter-list'>
          <li>
            <strong> Governing Law and Award</strong>
          </li>
        </ol>
        <p>
          The arbitrator shall follow the substantive law of the State of California without regard to its
          conflicts of laws principles. Any award rendered shall include a confidential written opinion and
          shall be final, subject to appeal under the FAA. Judgment on the award rendered by the arbitrator
          may be entered in any court of competent jurisdiction.
        </p>
        <ol start='9' className='letter-list'>
          <li>
            <strong>Enforceability</strong>
          </li>
        </ol>
        <p>
          This provision survives termination of your account or relationship with {company.name}, bankruptcy,
          assignment, or transfer. If the class action waiver is deemed unenforceable (i.e., unenforceability
          would allow arbitration to proceed as a class or representative action), then this entire
          arbitration provision shall be rendered null and void and shall not apply. If a portion of this
          arbitration provision (other than the class action waiver) is deemed unenforceable, the remaining
          portions of this arbitration provision shall remain in full force and effect.
        </p>
        <ol start='10' className='letter-list'>
          <li>
            <strong>Miscellaneous</strong>
          </li>
        </ol>
        <p>
          Failure or any delay in enforcing this arbitration provision in connection with any particular Claim
          will not constitute a waiver of any rights to require arbitration at a later time or in connection
          with any other Claims except all Claims must be brought within the 1 year limitation period set
          forth above. This provision is the entire arbitration agreement between you and {company.name} and
          shall not be modified except in writing by {company.name}.
        </p>
        <ol start='11' className='letter-list'>
          <li>
            <strong>Amendments</strong>
          </li>
        </ol>
        <p>
          {company.name} reserves the right to amend this arbitration provision at any time. Your continued
          use of the Website, purchase of a product on or through the Website, or use or attempted use of a{' '}
          {company.name}
          product, is affirmation of your consent to such changes. Should the changes to this arbitration
          provision be material, {company.name} will provide you notice and an opportunity to opt-out. Your
          continued use of the Website, purchase of a product on or through the Website, or use or attempted
          use of a {company.name} product, is affirmation of your consent to such material changes.
        </p>
        <p>
          YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL,
          AND CONFIDENTIAL ARBITRATION. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN
          THIRTY (30) DAYS FROM THE DATE THAT YOU PURCHASE, USE, OR ATTEMPT TO USE A PRODUCT PURCHASED ON OR
          THROUGH THE WEBSITE (WHICHEVER COMES FIRST) BY WRITING TO US AT {company.name}, LLC, ATTN:{' '}
          {company.address}. FOR YOUR OPT-OUT TO BE EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE
          IDENTIFYING ANY PRODUCT YOU PURCHASED, USED OR ATTEMPTED TO USE WITHIN THE 30 DAYS AND THE DATE YOU
          FIRST PURCHASED, USED OR ATTEMPTED TO USE THE PRODUCT. IF MORE THAN THIRTY (30) DAYS HAVE PASSED,
          YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING
          ARBITRATION AS SET FORTH IN THIS AGREEMENT.
        </p>
        <ol start='17'>
          <li>
            <strong>INDEMNIFICATION</strong>
          </li>
        </ol>
        <p>
          To the fullest extent permitted by law, you agree to indemnify, defend, and hold harmless{' '}
          {company.name}, its parent, subsidiaries, predecessors, successors and affiliates, and their
          respective partners, officers, directors, agents, representatives, contractors, licensors, service
          providers, subcontractors, suppliers, interns, and employees, from and against any and all claims,
          actions, losses, liabilities, damages, expenses, demands and costs of any kind, including, but not
          limited to, reasonable attorneys’ fees, arising out of, resulting from, or in any way connected with
          or related to (1) your breach of these Terms, the documents they incorporate by reference, or the
          Agreement; (2) your breach of any representations or warranties in this Agreement; or (3) your
          violation of any law or the rights of a third-party.
        </p>
        <ol start='18'>
          <li>
            <strong>THIRD-PARTY WEBSITES AND LINKS</strong>
          </li>
        </ol>
        <p>
          Our Website may include materials from third-parties or links to third-party websites. We are not
          liable for any third-party materials or websites. Please review carefully the third-party’s policies
          and practices and make sure you understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should be directed to the third-party.
        </p>
        <ol start='19'>
          <li>
            <strong>TESTIMONIALS, REVIEWS, AND OTHER SUBMISSIONS</strong>
          </li>
        </ol>
        <p>
          {company.name} may use testimonials and/or product reviews in whole or in part together with the
          name and state/country of the person submitting it. Testimonials may be used for any form of
          activity relating to {company.name}’s products, in printed and online media, as
          {company.name} determines in its absolute discretion. Testimonials represent the unique experience
          of the customers submitting the testimonial, and do not necessarily reflect the experience that you
          may have using our products. As noted in Section 4 above, your results will vary depending upon a
          variety of factors unique to you, such as your age, health, and genetics.
        </p>
        <p>
          Anything that you submit or post to the Website and/or provide us, including without limitation,
          photographs, testimonials, ideas, know-how, techniques, questions, reviews, comments, and
          suggestions (collectively, “Submissions”) is and will be treated as non-confidential and
          nonproprietary, and we shall have the royalty-free, worldwide, perpetual, irrevocable and
          transferable right to use, copy, distribute, display, publish, perform, sell, lease, transmit,
          adapt, and create derivative works from such Submissions by any means and in any form, and to
          translate, modify, reverse-engineer, disassemble, or decompile such Submissions. You represent and
          warrant that you are the owner or have sufficient rights to share the Submissions with us.  All
          Submissions shall automatically become our sole and exclusive property and shall not be returned to
          you.
        </p>
        <p>
          Additionally, {company.name} reserves the right to correct grammatical and typing errors, to shorten
          testimonials prior to publication or use, and to review all testimonials prior to publication or
          use.
          {company.name} shall be under no obligation to use any, or any part of, any testimonial or product
          review submitted. If you submit a testimonial, you are confirming that you have read, understood and
          agree to these Terms. If you disagree with any part of these Terms, do not submit a testimonial.
        </p>
        <ol start='20'>
          <li>
            <strong>DIGITAL MILLENNIUM COPYRIGHT ACT NOTICE</strong>
          </li>
        </ol>
        <p>
          This Website maintains specific contact information provided below, including an e-mail address, for
          notifications of claimed infringement regarding materials posted to this Website. All notices should
          be addressed to:
        </p>
        <p>Notification of Claimed Infringement</p>
        <p>{company.name}, LLC</p>
        <p>{company.address}</p>
        <p>Tel: {company.phone}</p>
        <p>Email: {company.email}</p>
        <p>
          You may contact us for notice of claimed infringement specified above with complaints regarding
          allegedly infringing posted material and we will investigate those complaints. If the posted
          material is believed in good faith by us to violate any applicable law, we will remove or disable
          access to any such material, and we will notify the posting party that the material has been blocked
          or removed.
        </p>
        <p>
          In notifying us of alleged copyright infringement, the Digital Millennium Copyright Act requires
          that you include the following information: (i) description of the copyrighted work that is the
          subject of claimed infringement; (ii) description of the infringing material and information
          sufficient to permit us to locate the alleged material; (iii) contact information for you, including
          your address, telephone number and/or e-mail address; (iv) a statement by you that you have a good
          faith belief that the material in the manner complained of is not authorized by the copyright owner,
          or its agent, or by the operation of any law; (v) a statement by you, signed under penalty of
          perjury, that the information in the notification is accurate and that you have the authority to
          enforce the copyrights that are claimed to be infringed; and (vi) a physical or electronic signature
          of the copyright owner or a person authorized to act on the copyright owner’s behalf. Failure to
          include all of the above-listed information may result in the delay of the processing of your
          complaint.
        </p>
        <ol start='21'>
          <li>
            <strong>ELECTRONIC COMMUNICATIONS</strong>
          </li>
        </ol>
        <p>
          You agree that we may communicate electronically with you and that such communications, as well as
          notices, disclosures, agreements, and other communications that we provide to you electronically,
          are equivalent to communications in writing and shall have the same force and effect as if they were
          in writing and signed by the party sending the communication.
        </p>
        <ol start='22'>
          <li>
            <strong>ASSIGNMENT</strong>
          </li>
        </ol>
        <p>
          You may not assign any of your rights under these Terms, and any such attempt will be null and
          void.  {company.name} and its affiliates may, in their individual discretion, transfer, without
          further consent or notification, all contractual rights and obligations pursuant to these Terms if
          some or all of {company.name}’s business is transferred to another entity by way of merger, sale of
          its assets or otherwise.
        </p>
        <ol start='23'>
          <li>
            <strong>NO WAIVER</strong>
          </li>
        </ol>
        <p>
          No waiver by {company.name} of any term or condition set forth in these Terms shall be deemed a
          further or continuing waiver of such term or condition or a waiver of any other term or condition,
          and any failure by {company.name} to assert a right or provision under these Terms shall not
          constitute a waiver of such right or provision.
        </p>
        <ol start='24'>
          <li>
            <strong>NO AGENCY RELATIONSHIP</strong>
          </li>
        </ol>
        <p>
          No joint venture, partnership, employment, or agency relationship exists between you and us as a
          result of you receiving a product from us or from using this Website.
        </p>
        <ol start='25'>
          <li>
            <strong>SEVERABILITY</strong>
          </li>
        </ol>
        <p>
          In the event that any provision of these Terms is determined to be unlawful, void or unenforceable,
          such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law,
          and the unenforceable portion shall be deemed to be severed from these Terms. Such determination
          shall not affect the validity and enforceability of any other remaining provisions.
        </p>
        <ol start='26'>
          <li>
            <strong>TERMINATION</strong>
          </li>
        </ol>
        <p>
          In the event that we terminate this Agreement, Sections 2-3, 14-17, 20-25, 27, as well as any
          representations, warranties, and other obligations made or taken by you, shall survive the
          termination of this Agreement.
        </p>
        <ol start='27'>
          <li>
            <strong>ENTIRE AGREEMENT</strong>
          </li>
        </ol>
        <p>
          These Terms, the Agreement, and any policies or operating rules posted by us on the Website or in
          respect to the Website constitutes the entire agreement and understanding between you and COMPANY,
          and supersedes and replaces any prior or contemporaneous agreements. Any ambiguities in the
          interpretation of these Terms or the Agreement shall not be construed against the drafting party.
        </p>
        <ol start='28'>
          <li>
            <strong>QUESTIONS OR ADDITIONAL INFORMATION</strong>
          </li>
        </ol>
        <p>
          If you purchased a product or service through the Website, please contact Customer Support by phone
          or email.
        </p>
        <p>{company.name}, LLC</p>
        <p>{company.address}</p>
        <p>Tel: {company.phone}</p>
        <p>Email:  {company.email}</p>
      </div>
    </Modal>
  );
}
