export function klaviyoStartedCheckout(product) {
	const _learnq = window._learnq || [];

	_learnq.push([
		'track',
		'Started Checkout',
		{
			$event_id: new Date().getTime(),
			$value: product.productPrice,
			ItemNames: [product.productName],
			CheckoutURL: window.location.href,
			Items: [
				{
					ProductID: product.productId,
					ProductName: product.productName,
					Quantity: product.productQty,
					ItemPrice: product.productPrice,
					RowTotal: product.productPrice,
					ProductURL: window.location.href,
				},
			],
		},
	]);
}
