// eslint-disable-next-line import/no-anonymous-default-export
export const errorMessages = {
	0: {
		creditCardNumber: 'Please enter a valid credit card number',
		CVV: 'Please enter a valid CVV code',
	},
	1: {
		billingFirstName: 'Please enter your billing first name',
		billingLastName: 'Please enter your billing last name',
		billingAddress1: 'Please enter your billing address',
		billingCity: 'Please enter your billing city',
		billingState: 'Please select your billing state/province',
		billingCountry: 'Please select your billing country',
		billingZip: 'Please enter your billing zip/postal code',
	},
};
