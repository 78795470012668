import React from 'react';
import Modal from 'react-modal';
import '../Custom.sass';
import './PayPalModal.sass';

export default function PayPalModal({ content, modalIsOpen, closeModal }) {
	Modal.setAppElement('#root');
	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.75)',
			overflowY: 'scroll',
			zIndex: 1000,
		},
		content: {
			padding: 0,
			inset: '20px 50% auto',
			width: '375px',
			height: '90%',
			left: '50%',
			top: '30px',
			border: 'none',
			maxWidth: '95%',
			maxHeight: '840px',
			transform: 'translateX(-50%)',
		},
	};

	return (
		<Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
			<div className='custom-modal paypal-modal'>
				<button onClick={closeModal} className='custom-modal__close'>
					<i className='far fa-times'></i>
				</button>
				{content.error_message ? (
					content.error_message
				) : (
					<iframe
						width={365}
						height={840}
						frameBorder='0'
						title='PayPal payment'
						src={'data:text/html;charset=utf-8,' + encodeURI(content)}
					></iframe>
				)}
			</div>
		</Modal>
	);
}
