import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import NoData from '../../components/NoData/NoData';
import ImageOnly from './Layouts/ImageOnly/ImageOnly';
import ColorSelector from './Layouts/ColorSelector/ColorSelector';
import FreeProducts from './Layouts/FreeProducts/FreeProducts';
import Default from './Layouts/Default/Default';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import { Context as OrderContext } from '../../context/OrderContext';
import { PageView } from '../../utils/tracking';
import { buildQueryParams } from '../../utils/helpers';
import './SpecialOffer.sass';

export default function SpecialOffer(props) {
	// eslint-disable-next-line no-restricted-globals
	const queryParams = buildQueryParams(location);
	const { offer } = props;
	const history = useHistory();
	const { id: specialOfferId } = useParams();

	const currentSpecialOfferIndex = offer.specialOfferIds.indexOf(specialOfferId);

	const {
		state: { specialOffer, loading, error },
		loadSpecialOffer,
	} = useContext(SpecialOfferContext);

	const {
		state: { order },
		updateOrder,
		loadOrder,
	} = useContext(OrderContext);

	useEffect(() => {
		PageView();
		loadOrder(queryParams.orderid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (specialOffer.template) {
			document.querySelector('body').classList.add(specialOffer.template.name || 'special-offer-template');
		}
	}, [specialOffer]);

	useEffect(() => {
		loadSpecialOffer(specialOfferId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specialOfferId]);

	async function handleSubmit(product) {
		if (product) {
			const productId = product.id || product.stickyId;
			const productPrice = product.price ? product.price.substring(1) * 1 : 0;
			const newUpsellIds = order.upsellProductIds ? order.upsellProductIds + ',' + productId : productId;

			await updateOrder(order._id, {
				hasWebTv: specialOffer?.template?.name === 'FreeProducts',
				upsellProductIds: newUpsellIds,
				upsellCount: order.upsellCount * 1 + 1,
				orderTotal: order.orderTotal + productPrice,
				custom_fields: [...order.custom_fields, { id: '22', value: true }],
				specialOffers: [
					...order.specialOffers,
					{
						name: specialOffer.name,
						id: specialOffer._id,
						index: currentSpecialOfferIndex,
						productId,
					},
				],
			});
		}
		loadNextSpecialOffer();
		document.querySelector('body').classList.remove(specialOffer.template.name || 'special-offer-template');
	}

	function loadNextSpecialOffer() {
		if (offer.specialOfferIds[currentSpecialOfferIndex + 1]) {
			history.push({
				pathname: `/specialoffer/${offer.specialOfferIds[currentSpecialOfferIndex + 1]}`,
				// eslint-disable-next-line no-restricted-globals
				search: location.search,
			});
		} else {
			history.push({
				pathname: '/thank-you',
				// eslint-disable-next-line no-restricted-globals
				search: location.search,
			});
		}
	}

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error error={error} />;
	}

	if (!Object.keys(specialOffer).length) {
		return <NoData />;
	}

	function renderLayout() {
		// TODO create a way to manage special offer templates via API
		let Template = Default;

		if (specialOffer.template.name === 'ColorSelector') {
			Template = ColorSelector;
		}
		if (specialOffer.template.name === 'FreeProducts') {
			Template = FreeProducts;
		}
		if (specialOffer.template.name === 'ImageOnly') {
			Template = ImageOnly;
		}

		return <Template offer={offer} order={order} specialOffer={specialOffer} onSubmit={handleSubmit} />;
	}

	return (
		<main className='page-main'>
			<div className='container'>{renderLayout()}</div>
		</main>
	);
}
