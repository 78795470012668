/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import Header from './components/Header/Header';
import Error from './components/Error/Error';
import Footer from './components/Footer/Footer';
import NoData from './components/NoData/NoData';
import Loading from './components/Loading/Loading';
import ContactUsModal from './components/Modals/ContactUsModal/ContactUsModal';
import PrivacyModal from './components/Modals/PrivacyModal/PrivacyModal';
import TermsModal from './components/Modals/TermsModal/TermsModal';
import PayPalModal from './components/Modals/PayPalModal/PayPalModal';
import UpsellModal from './components/Modals/UpsellModal/UpsellModal';
import Checkout from './containers/Checkout/Checkout';
import SpecialOffer from './containers/SpecialOffer/SpecialOffer';
import ThankYouPage from './containers/ThankYouPage/ThankYouPage';
import { Context as AppContext } from './context/AppContext';
import { Context as OfferContext } from './context/OfferContext';
import { Context as OrderContext } from './context/OrderContext';
import { Context as PromoContext } from './context/PromoContext';
import { Context as ReviewsContext } from './context/ReviewsContext';
import { Context as CrossSellsContext } from './context/CrossSellsContext';
import { Context as FreeProductsContext } from './context/FreeProductsContext';
import { Preloader } from './components/Preloader/Preloader';
import { PageView, initGA } from './utils/tracking';
import { buildQueryParams, getAffiliateCbValue, stringToBase64 } from './utils/helpers';
import './App.sass';

function App() {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = buildQueryParams(location);
  const queryParamsCaseSaved = buildQueryParams(location, true);
  const history = useHistory();

  // Contexts
  const {
    state: { offer, loading: offerLoading, error: offerError },
    loadOffer,
    mergeOfferWithPromo,
  } = useContext(OfferContext);

  const {
    state: { order, error: orderError },
    createOrder,
    updateOrder,
  } = useContext(OrderContext);

  const {
    state: { promo, loading: promoLoading, error: promoError },
    loadPromo,
  } = useContext(PromoContext);

  const {
    state: { reviews, error: reviewsError },
    loadReviews,
  } = useContext(ReviewsContext);

  const {
    state: { crossSells, error: crossSellsError },
    loadCrossSells,
  } = useContext(CrossSellsContext);

  const {
    state: { freeProducts, error: freeProductsError },
    loadFreeProducts,
  } = useContext(FreeProductsContext);

  const { sendMessageFromContactForm } = useContext(AppContext);

  // App state
  const [contactUsModalIsOpen, setContactUsModalOpen] = useState(false);
  const [privacyModalIsOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalIsOpen, setTermsModalOpen] = useState(false);
  const [payPalModalIsOpen, setPayPalModalOpen] = useState(false);
  const [payPalContent, setPayPalContent] = useState('');
  const [upsellModalIsOpen, setUpsellModalOpen] = useState(false);
  const [isPreloaderFinished, setPreloaderFinished] = useState(false);
  const [promoWasMerged, setPromoWasMerged] = useState(false);

  const isOneLineCheckout = queryParams.olc === '1';
  const isOneProductCheckout = queryParams.opc === '1' || offer?.template === 2;
  const isSkipModal = queryParams.skipmodal === '1';

  // API Requests
  useEffect(() => {
    if (queryParams.offerid) {
      (async () => {
        const offer = await loadOffer(queryParams.offerid);
        await loadFreeProducts(offer._id);

        if (offer.reviews.length > 0) {
          await loadReviews(offer.reviews.map((item) => item.id));
        }
        if (offer.crossSells.length > 0) {
          await loadCrossSells(offer.crossSells.map((item) => item.id));
        }
      })();
    }

    if (queryParams.promoid) {
      (async () => {
        await loadPromo(queryParams.promoid);
      })();
    }

    if (queryParams.preloader === '0') {
      setPreloaderFinished(true);
    }

    localStorage.setItem('initialCheckoutUrl', window.location.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offer.gtmId) {
      const tagManagerArgs = {
        gtmId: offer.gtmId,
      };

      TagManager.initialize(tagManagerArgs);
    }

    initGA(offer?.gaId);
    PageView();

    if (offer.name) {
      document.title = `Checkout | ${offer.name}`;
    }

    if (offer.faviconSrc) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = offer.faviconSrc;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    // eslint-disable-next-line no-console
    console.log('RIMCRM');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  useEffect(() => {
    // Merging promo with offer only after offer was loaded
    // if we have promo loaded, and offer is loaded, and promo offer id is same as offer.id and promo was not merged yet
    if (promo && offer && Object.keys(offer).length > 0 && promo.offerId === offer._id && !promoWasMerged) {
      mergeOfferWithPromo(promo);
      setPromoWasMerged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo, offer]);

  function submitContactUsForm(data) {
    sendMessageFromContactForm({
      ...data,
      domain: offer.company.website,
      to: `support@${offer.company.website}`,
    });
  }

  async function handleCreateOrder(data) {
    let everflowTransactionId = '';
    window.dataLayer = window.dataLayer || [];

    // for internal traffic we use direct links and generate transaction id from click
    if (window.EF) {
      everflowTransactionId = window.EF.getTransactionId(queryParams.oid || offer.everflowOfferId);
    }

    // for external affiliates we use click_id from url because they have redirect links
    if (queryParams.click_id) {
      everflowTransactionId = queryParams.click_id;
    }

    const newOrder = await createOrder({
      ...data,
      everflowTransactionId,
      userAgent: navigator.userAgent,
      orderSourceUrl: window.location.href,
      tid: offer.gaId,
      cid: window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : '',
      affId: queryParams.affid || Cookies.get('affid'),
      afId: queryParams.affid || Cookies.get('affid'),
      sid: queryParams.sid,
      c1: queryParams.c1 || queryParams.sub1,
      c2: queryParams.c2 || queryParams.sub2,
      c3: queryParams.c3 || queryParams.sub3,
      revContentId: queryParams.rc_uuid,
      click_id: everflowTransactionId,
      offerId: offer._id,
      offerName: offer.name,
      cookie: document.cookie,
      notes: window.location.href,
      utm_medium: queryParams.sub4 || Cookies.get('sub4'),
      everflowCb: getAffiliateCbValue(window.dataLayer),
      taboolaClickId: queryParamsCaseSaved.taboola_click_id,
    });

    // Events that should happen right after order is created.
    // Push custom variable value with revenue for GTM
    window.dataLayer.push({ orderValue: newOrder.orderTotal });
    window.dataLayer.push({
      transactionId: stringToBase64(data.email),
    });

    // send custom Order Created event to Google Tag Manager
    window.dataLayer.push({
      event: 'orderCreated',
    });

    history.push({
      search: location.search + `&orderId=${newOrder._id}`,
    });

    if (newOrder.isPayPalOrder) {
      localStorage.setItem('orderId', newOrder._id);
      setPayPalContent(newOrder.processedResponseData);
      setPayPalModalOpen(true);
      return;
    }

    if (isSkipModal) {
      handleUpsellModalSubmit();
    } else {
      setUpsellModalOpen(true);
    }
  }

  function redirectToPage(path) {
    history.push({
      pathname: path,
      search: location.search + `&preloader=0`,
    });
  }

  async function updateOrderUpsells(id) {
    let ids = order.upsellProductIds.split(',');
    let count = order.upsellCount;

    if (!ids.includes(id)) {
      ids.push(id);
      count = count * 1 + 1;
    } else {
      ids = ids.filter((item) => item !== id);
      count = count * 1 - 1;
    }

    try {
      await updateOrder(queryParams.orderid || order._id, {
        upsellProductIds: ids.filter((item) => item).join(),
        upsellCount: count.toString(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error);
    }
  }

  async function handleUpsellModalSubmit(product) {
    // if NO product was selected
    if (!product) {
      setUpsellModalOpen(false);
      // redirect to first upsell or finish the order
      if (offer.specialOfferIds[0]) {
        redirectToPage(`/specialoffer/${offer.specialOfferIds[0]}`);
      } else {
        redirectToPage('/thank-you');
      }
      return;
    }

    // if product was selected
    await updateOrderUpsells(product.stickyId);
    await updateOrder(queryParams.orderid || order._id, {
      specialOffers: [...order.specialOffers, { name: 'Upsell Modal', id: product.stickyId, index: 0 }],
      custom_fields: [...order.custom_fields, { id: '22', value: true }],
      hasWebTv: true,
    });

    setUpsellModalOpen(false);

    // redirect to second upsell or finish the order
    if (offer.specialOfferIds[1]) {
      redirectToPage(`/specialoffer/${offer.specialOfferIds[1]}`);
    } else {
      redirectToPage('/thank-you');
    }
  }

  function renderModals() {
    return (
      <>
        <ContactUsModal
          modalIsOpen={contactUsModalIsOpen}
          closeModal={() => setContactUsModalOpen(false)}
          company={offer.company}
          onFormSubmit={submitContactUsForm}
        />
        <PrivacyModal
          modalIsOpen={privacyModalIsOpen}
          closeModal={() => setPrivacyModalOpen(false)}
          company={offer.company}
        />
        <TermsModal
          modalIsOpen={termsModalIsOpen}
          closeModal={() => setTermsModalOpen(false)}
          company={offer.company}
          onPrivacyClick={() => setPrivacyModalOpen(true)}
        />
        <PayPalModal
          modalIsOpen={payPalModalIsOpen}
          closeModal={() => setPayPalModalOpen(false)}
          content={payPalContent}
        />
        <UpsellModal
          modalIsOpen={upsellModalIsOpen}
          closeModal={() => handleUpsellModalSubmit()}
          onSubmit={handleUpsellModalSubmit}
          company={offer.company}
          webtvDisclaimer={offer.webtvDisclaimer}
          freeProducts={freeProducts}
        />
      </>
    );
  }

  if (offerLoading || promoLoading) {
    return <Loading />;
  }

  if (offerError || orderError || promoError || reviewsError || freeProductsError) {
    return (
      <Error
        error={offerError || orderError || promoError || reviewsError || freeProductsError || crossSellsError}
      />
    );
  }

  if (!offer || !Object.keys(offer).length) {
    return <NoData />;
  }

  if (!isPreloaderFinished) {
    return (
      <Preloader
        onFinish={() => setPreloaderFinished(true)}
        preloaderInfo={offer.preloader}
        logoSrc={offer.logoSrc}
      />
    );
  }

  return (
    <>
      {renderModals()}
      <Header logoSrc={offer.logoSrc} />
      <Switch>
        <Route path='/specialoffer/:id'>
          <SpecialOffer offer={offer} />
        </Route>
        <Route path='/thank-you'>
          <ThankYouPage company={offer.company} brand={offer.brand} />
        </Route>
        <Route path='/'>
          <Checkout
            offer={offer}
            reviews={reviews}
            crossSells={crossSells}
            onCreateOrder={handleCreateOrder}
            isOneLineCheckout={isOneLineCheckout}
            isOneProductCheckout={isOneProductCheckout}
            onTermsClick={() => setTermsModalOpen(true)}
            onPrivacyClick={() => setPrivacyModalOpen(true)}
          />
        </Route>
      </Switch>

      <Footer
        brand={offer.company?.name}
        openContactUsModal={() => setContactUsModalOpen(true)}
        openPrivacyModal={() => setPrivacyModalOpen(true)}
        openTermsModal={() => setTermsModalOpen(true)}
      />
    </>
  );
}

export default App;
