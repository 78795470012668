/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import FreeProductList from '../../FreeProductList/FreeProductList';
import useInterval from '../../../hooks/useInterval';
import './UpsellModal.sass';
import ultraLinkImage from './ultra.jpeg';
import elementImage from './element.jpeg';

export default function UpsellModal({
  modalIsOpen,
  closeModal,
  company,
  freeProducts,
  onSubmit,
  webtvDisclaimer,
}) {
  const illumiwaveOffers = ['625607764dab950011f7652c', '649da2d3f1e2240011191f1c'];
  const isIllumiwave = illumiwaveOffers.some((offer) => location.href.includes(offer));

  const ultraLinkOffers = [
    '6508b878c280040011fc01a9',
    '659d9685c7f4c20011f7b0c5',
    '65b7f628affa1f001100614b',
    '65c273b4c1867300112f544a',
    '65d2381dc1867300112f99ad',
  ];
  const isUltraLink = ultraLinkOffers.some((offer) => location.href.includes(offer));

  const cleansingBrushOffers = ['653ffad68c229800115b65c9'];
  const isCleansingBrush = cleansingBrushOffers.some((offer) => location.href.includes(offer));

  const himitsuOffers = ['62ab61b8b7ed3f0011ae10e1', '64382c6f4323df0011d8f2c3'];
  const isHimitsu = himitsuOffers.some((offer) => location.href.includes(offer));

  const [seconds, setSeconds] = useState(60);
  const [isRunning, setIsRunning] = useState(false);

  Modal.setAppElement('#root');

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      overflowY: 'scroll',
      zIndex: 1000,
    },
    content: {
      padding: 0,
      inset: '20px 50% auto',
      width: '650px',
      left: '50%',
      top: '30px',
      border: 'none',
      maxWidth: '95%',
      transform: 'translateX(-50%)',
    },
  };

  function countdown() {
    if (seconds <= 0) {
      document.getElementById('countdown').innerHTML = '<span>0</span><span>0</span>';
    } else {
      const first = seconds >= 10 ? seconds.toString()[0] : 0;
      const second = seconds >= 10 ? seconds.toString()[1] : seconds;
      document.getElementById('countdown').innerHTML = `<span>${first}</span><span>${second}</span>`;
    }
    setSeconds(seconds - 1);
  }

  useInterval(
    () => {
      !isIllumiwave && !isUltraLink && !isCleansingBrush && !isHimitsu && countdown();
    },
    isRunning ? 1000 : null
  );

  useEffect(() => {
    if (modalIsOpen) {
      setIsRunning(true);
    } else {
      setIsRunning(false);
    }
  }, [modalIsOpen]);

  function getTitle() {
    if (isIllumiwave) {
      return 'Maximize Your Results With Element';
    }

    if (isCleansingBrush) {
      return 'Maximize Your Results With Element';
    }

    if (isHimitsu) {
      return 'Maximize Your Results With Element';
    }

    if (isUltraLink) {
      return 'Maximize Your Signal with the UltraLink Amplifier';
    }

    return 'Claim Your Free Gift In';
  }

  function getSubtitle() {
    if (isIllumiwave) {
      return 'Congratulations! Your order is eligible for a FREE Element 3-Step Routine (FREE SHIPPING)';
    }
    if (isCleansingBrush) {
      return 'Congratulations! Your order is eligible for a FREE Element 3-Step Routine (FREE SHIPPING)';
    }
    if (isHimitsu) {
      return 'Congratulations! Your order is eligible for a FREE Element 3-Step Routine (FREE SHIPPING)';
    }
    if (isUltraLink) {
      return 'Congratulations! Your order is eligible for a FREE Amplifier Upgrade (FREE SHIPPING)';
    }

    return 'Congratulations! Your order is eligible for a Free Gift with access to Membership Rewards! Pick one from the options below.';
  }

  function getDisclaimerText() {
    const defaultDisclaimer = `This exclusive offer is only available to new Membership Rewards customers. Included is 1 month of free membership services including exclusive discounts, newsletters, access to our extensive video library, and will subscribe at a monthly discounted rate thereafter of $19.97. Cancel anytime by calling ${company.phone} or by emailing ${company.email}`;

    if (isIllumiwave) {
      return `This exclusive offer is only available to new Element Skin Care customers. Included with your order today is a FREE 30 day Element Skin Care bundle that includes a top-of-the-line Moisturizer, Vitamin C Serum, and Under Eye Cream and will subscribe and continue at the monthly discount rate of just 59.95/mo thereafter. Membership may be adjusted at any time by calling ${company.phone} or by emailing ${company.email}`;
    }

    if (isUltraLink) {
      return `This exclusive offer is only available to new Membership Rewards customers. Included with your order today is a free amplifier upgrade for trying our Membership Rewards services that includes access to exclusive content, newsletters, free monthly product codes, and more, and will continue at the monthly discount rate of just 9.97/mo thereafter. Membership may be adjusted at any time by calling ${company.phone} or by emailing ${company.email}`;
    }

    if (isCleansingBrush) {
      return `This exclusive offer is only available to new Cleansing Brush customers. Included is a 1 month supply of Element Skin Care Vitamin C Serum, Under Eye Vibrancy Cream, and Rejuvenating Night Time Moisturizer, and will subscribe at a monthly discounted rate thereafter of 79.95. Cancel anytime by calling ${company.phone} or by emailing ${company.email}`;
    }

    if (isHimitsu) {
      return `This exclusive offer is only available to new Element Skin Care customers. Included with your order today is a FREE 30 day Element Skin Care bundle that includes a top-of-the-line Moisturizer, Vitamin C Serum, and Under Eye Cream and will subscribe and continue at the monthly discount rate of just 59.95/mo thereafter. Membership may be adjusted at any time by calling ${company.phone} or by emailing ${company.email}`;
    }

    return webtvDisclaimer || defaultDisclaimer;
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      <div className='free-product-modal'>
        <div className='free-product-modal-warning'>HURRY UP! LIMITED QUANTITY AVAILABLE</div>
        <div className='free-product-modal-header'>
          <h4>
            {getTitle()}

            {!isIllumiwave && !isUltraLink && !isCleansingBrush && !isHimitsu && (
              <div id='countdown' style={{ display: 'inline', textAlign: 'center' }}>
                <span>6</span>
                <span>0</span>
              </div>
            )}
          </h4>
          <p>{getSubtitle()}</p>
        </div>

        {isIllumiwave && (
          <div className='free-product-element'>
            <img src={elementImage} alt='element' />
            <button
              className='free-product-list-product-btn'
              onClick={() => onSubmit({ stickyId: 2622, name: 'Element Skin Care' })}
            >
              Click here to claim gift
            </button>
          </div>
        )}

        {isCleansingBrush && (
          <div className='free-product-element'>
            <img src={elementImage} alt='element' />
            <button
              className='free-product-list-product-btn'
              onClick={() => onSubmit({ stickyId: 2407, name: 'Element Skin Care' })}
            >
              Click here to claim gift
            </button>
          </div>
        )}

        {isHimitsu && (
          <div className='free-product-element'>
            <img src={elementImage} alt='element' />
            <button
              className='free-product-list-product-btn'
              onClick={() => onSubmit({ stickyId: 2622, name: 'Element Skin Care' })}
            >
              Click here to claim gift
            </button>
          </div>
        )}

        {isUltraLink && (
          <div className='free-product-element'>
            <img src={ultraLinkImage} alt='element' />
            <button
              className='free-product-list-product-btn'
              onClick={() => onSubmit({ stickyId: 2533, name: 'UltraLink Amplifier' })}
            >
              Click here to claim gift
            </button>
          </div>
        )}

        {!isIllumiwave && !isUltraLink && !isCleansingBrush && !isHimitsu && (
          <FreeProductList isModal productList={freeProducts} onProductClick={onSubmit} />
        )}

        <div className='free-product-modal-description'>
          {!isIllumiwave && !isUltraLink && !isCleansingBrush && !isHimitsu && (
            <i className='far fa-chevron-double-down animate__animated animate__fadeInDown animate__infinite animate__slow'></i>
          )}

          {getDisclaimerText()}
        </div>

        <div className='free-product-modal-buttons'>
          <div className={`free-product-modal-buttons-btn`} id='warranty-no' onClick={() => onSubmit()}>
            NO, I DON’T LIKE FREE STUFF
          </div>
        </div>
      </div>
    </Modal>
  );
}
