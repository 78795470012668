import { useEffect, useRef, useState } from 'react';
import './Preloader.sass';
import trustBadges from './trustbadgesloader.png';

export function Preloader({ logoSrc, preloaderInfo, onFinish }) {
	const [stepName, setStepName] = useState('Step 1');
	const [stepDescription, setStepDescription] = useState(
		'Checking Availability...'
	);
	const units = useRef(getRandomInt(15, 32));
	const timeouts = [];
	// Steps for loader
	const steps = [
		{
			title: 'Step 1',
			subtitle: 'Checking availability',
			itemId: 1,
			nameDisplay: 'none',
			statusDisplay: 'none',
		},
		{
			title: 'Step 1',
			subtitle: 'Checking availability',
			itemId: 1,
			nameDisplay: 'block',
			statusDisplay: 'none',
		},
		{
			title: 'Step 1',
			subtitle: 'Checking availability',
			itemId: 1,
			nameDisplay: 'block',
			statusDisplay: 'block',
		},
		{
			title: 'Step 1',
			subtitle: 'Checking availability',
			itemId: 2,
			nameDisplay: 'block',
			statusDisplay: 'none',
		},
		{
			title: 'Step 1',
			subtitle: 'Checking availability',
			itemId: 2,
			nameDisplay: 'block',
			statusDisplay: 'block',
		},
		{
			title: 'Step 2',
			subtitle: 'Checking Discount',
			itemId: 3,
			nameDisplay: 'block',
			statusDisplay: 'none',
		},
		{
			title: 'Success',
			subtitle: 'Your Order is Ready!',
			itemId: 3,
			nameDisplay: 'block',
			statusDisplay: 'block',
		},
	];

	// Generates random int from min to max
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
	}

	document.body.addEventListener('keydown', function (event) {
		const key = event.key;
		const cmd_held = event.metaKey;

		if (cmd_held && key.toLowerCase() === "'") {
			timeouts.forEach((item) => clearTimeout(item));
			onFinish();
		}
	});

	useEffect(() => {
		initialRun();

		return () => {
			setStepName('');
			setStepDescription('');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function initialRun() {
		// Percents steps [start, end]
		const ranges = [
			[0, 10],
			[11, 22],
			[23, 35],
			[36, 50],
			[51, 75],
			[76, 100],
		];

		// Creating bar movements based on timeouts
		const interval = 2500;

		for (let i = 0; i < ranges.length; i++) {
			const [start, end] = ranges[i];

			timeouts.push(
				setTimeout(() => {
					moveBar(start, end, i);
				}, interval * i)
			);
		}
	}

	// Moving loading bar and updating texts and blocks visibility
	function moveBar(start, end, index) {
		const step = steps[index];
		const elem = document.getElementById('loader-bar');
		const name = document.querySelector(
			`.preloader-checklist__item[data-id='${step.itemId}'] .preloader-checklist__item-name`
		);
		const status = document.querySelector(
			`.preloader-checklist__item[data-id='${step.itemId}'] .preloader-checklist__item-status`
		);

		if (!name || !status || !elem) {
			return;
		}

		let width = start;
		const interval = setInterval(updateWidth, 50);

		function updateWidth() {
			if (width >= end) {
				clearInterval(interval);
				// Finishing preloader
				if (end >= 100) {
					setTimeout(function () {
						onFinish();
					}, 1500);
				}
			} else {
				width++;
				elem.style.width = width + '%';
				elem.innerHTML = width + '%';
			}
		}

		setStepName(step.title);
		setStepDescription(step.subtitle);

		name.style.display = step.nameDisplay;
		status.style.display = step.statusDisplay;
	}

	return (
		<div className='preloader'>
			<div className='preloader__header'>
				<img src={logoSrc} alt='logo' />
			</div>

			<div className='preloader-content'>
				<div className='preloader-body'>
					<div className='preloader__title js-title'>{stepName}</div>
					<div className='preloader__subtitle js-subtitle'>
						{stepDescription}
					</div>
					<div className='preloder-bar'>
						<div className='preloder-bar__bg' id='loader-bar'>
							0%
						</div>
					</div>
					<div className='preloader-checklist'>
						<div className='preloader-checklist__item' data-id='1'>
							<div className='preloader-checklist__item-name'>
								Checking Warehouse #1...
							</div>
							<div
								className='preloader-checklist__item-status'
								style={{
									display: 'none',
								}}
							>
								<i className='fas fa-times-circle'></i> SOLD OUT
							</div>
						</div>
						<div className='preloader-checklist__item' data-id='2'>
							<div
								className='preloader-checklist__item-name'
								style={{
									display: 'none',
								}}
							>
								Checking Warehouse #2...
							</div>
							<div
								className='preloader-checklist__item-status'
								style={{
									display: 'none',
								}}
							>
								<i className='fas fa-check-circle'></i>{' '}
								<span>{units.current}</span> Units Available
							</div>
						</div>
						<div className='preloader-checklist__item' data-id='3'>
							<div
								className='preloader-checklist__item-name'
								style={{
									display: 'none',
								}}
							>
								Verifying Current Offer...
							</div>
							<div
								className='preloader-checklist__item-status'
								style={{
									display: 'none',
								}}
							>
								<i className='fas fa-check-circle'></i>{' '}
								<b>{preloaderInfo.discount}</b> Discount Applied
							</div>
						</div>
					</div>
				</div>

				<div className='preloader-footer'>
					<div className='preloader-footer__img'>
						<p>Ads Seen On</p>
						<img src={trustBadges} alt='ads seen on' />
					</div>
					<div className='preloader-footer__review'>
						<div className='preloader-footer__review-rating'>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							5/5
						</div>
						<div className='preloader-footer__review-text'>
							"{preloaderInfo.review.text}"
						</div>
						<div className='preloader-footer__review-author'>
							- {preloaderInfo.review.author}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
