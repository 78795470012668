import ReactGA from 'react-ga';
/* this is for initialize GA setup */
export const initGA = (trackingId) => {
	ReactGA.initialize(trackingId);
};
/* this is for getting url website when user access our websites */
export const PageView = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
};
/**
 * Event - Add custom tracking event.
 * This's for tracking when event clicked something like that they'll send the data to GA
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {string} value
 */
export const Event = (category, action, label, value) => {
	ReactGA.event({
		category,
		action,
		label,
		value,
	});
};

/**
 * Event - Add product to ecommerce
 * @param {string} id
 * @param {string} name
 * @param {string} price
 * @param {string} quantity
 */
export function Product(id, name, price, quantity) {
	ReactGA.plugin.execute('ecommerce', 'addProduct', {
		id,
		name,
		price,
		quantity,
	});
}

/**
 * Fire purchase for ecommerce
 * @param {string} id
 * @param {string} affid
 * @param {string} revenue
 */
export function Transaction(id, affid, revenue) {
	ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
		id,
		affiliation: affid,
		revenue,
	});
}
