/* eslint-disable no-restricted-globals */
import React, { useState, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import queryString from 'query-string';
import xssFilters from 'xss-filters';
import ProductSelect from '../ProductSelect/ProductSelect';
import ShippingInfo from '../ShippingInfo/ShippingInfo';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import CrossSells from '../CrossSells/CrossSells';
import orderReducer from '../../reducers/orderReducer';
import api from '../../api/api';
import { validateStep } from '../../utils/orderFormUtils';
import { buildQueryParams } from '../../utils/helpers';
import { errorMessages as shippingErrorMessages } from '../ShippingInfo/errorMessages';
import './OrderForm.sass';
import { klaviyoStartedCheckout } from '../../utils/klaviyo';

export default function OrderForm({
	offer,
	crossSells,
	onCreateOrder,
	onPrivacyClick,
	onTermsClick,
	isOneLineCheckout,
	isOneProductCheckout,
}) {
	// eslint-disable-next-line no-restricted-globals
	const queryParams = buildQueryParams(location);
	const [stepIndex, setStepIndex] = useState(1);
	const [ipAddress, setIpAddress] = useState('');
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		let componentMounted = true;

		async function getUserIpAddress() {
			try {
				const res = await axios.get('https://geolocation-db.com/json/');
				if (res.data && componentMounted) {
					setIpAddress(res.data.IPv4);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Ip address not found.');
			}
		}

		getUserIpAddress();
		return () => {
			componentMounted = false;
		};
	}, []);

	useEffect(() => {
		if (stepIndex === 3) {
			createKlaviyoProspect({
				orderData,
				offerId: offer._id,
			});
			createStickyProspect(orderData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepIndex]);

	// if it's prefilled checkout directly from interstitial
	useEffect(() => {
		if (queryParams.productid && queryParams.total && offer.productsData) {
			const selected = offer.productsData.find((item) => item.id === queryParams.productid);
			const upsellCount = queryParams.upsellproductids
				? queryParams.upsellproductids.split(',').length + 1
				: 1;
			const dynamicProductsData = getDynamicProductsData(queryParams.upsellproductids.split(','));

			handleSaveStep(
				{
					...orderData,
					productId: selected.id,
					productQty: selected.quantity,
					productName: selected.name,
					productPrice: selected.price,
					orderTotal: queryParams.total * 1,
					upsellCount,
					upsellProductIds: `2170${queryParams.upsellproductids ? `,${queryParams.upsellproductids}` : ''}`,
					dynamicProductsData,
				},
				2
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer, queryParams.productid]);

	useEffect(() => {
		if (crossSells.length > 0) {
			preselectCrossSells();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crossSells]);

	// Everflow conversion ADD TO CART
	useEffect(() => {
		window?.EF?.conversion({
			aid: 1,
			adv_event_id: 4,
			order: { items: [] },
		});
	}, []);

	function getDynamicProductsData(pids) {
		const result = {};

		pids.forEach((pid) => {
			if (result[`product_qty_${pid}`]) {
				result[`product_qty_${pid}`] += 1;
			} else {
				result[`product_qty_${pid}`] = 1;
			}
		});

		return result;
	}

	async function createStickyProspect(data) {
		data.AFFID = queryParams.affid || Cookies.get('affid');
		data.AFID = queryParams.affid || Cookies.get('affid');
		data.SID = queryParams.sid;
		data.C1 = queryParams.c1 || queryParams.sub1;
		data.C2 = queryParams.c2 || queryParams.sub2;
		data.C3 = queryParams.c3 || queryParams.sub3;
		data.ipAddress = ipAddress;
		data.click_id =
			queryParams.click_id || window.EF
				? window.EF.getTransactionId(offer.everflowOfferId)
				: 'EF script was not found';
		try {
			await api.post('/newStickyProspect', data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Sticky Prospect error: ', error);
		}
	}

	async function createKlaviyoProspect(data) {
		data.orderData.promoid = queryParams.promoid;
		try {
			await api.post('/newKlaviyoProspect', data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Klaviyo Prospect error: ', error);
		}
	}

	function getFbcValue() {
		if (Cookies.get('_fbc')) {
			return Cookies.get('_fbc');
		}

		if (queryParams.fbclid) {
			const timestamp = new Date().getTime();
			return `fb.1.${timestamp}.${queryParams.fbclid}`;
		}

		return '';
	}

	function getCustomFields() {
		const result = [];
		const fields = {
			17: queryString.parse(location.search).wbraid,
			16: queryString.parse(location.search).gbraid,
			15: queryString.parse(location.search).gclid,
		};

		Object.keys(fields).forEach((key) => {
			if (fields[key]) {
				result.push({
					id: key,
					value: fields[key],
				});
			}
		});

		return result;
	}

	const initialOrderState = {
		productId: offer.productsData[0].id || '',
		productQty: offer.productsData[0].quantity || '',
		productName: offer.productsData[0].name || '',
		productPrice: offer.productsData[0].price || '',
		email: '',
		firstName: '',
		lastName: '',
		shippingAddress1: '',
		shippingCity: '',
		shippingState: '',
		shippingCountry: 'US',
		shippingZip: '',
		phone: '',
		creditCardNumber: '',
		expirationDate: '',
		CVV: '',
		billingFirstName: '',
		billingLastName: '',
		billingAddress1: '',
		billingCity: '',
		billingState: '',
		billingCountry: 'US',
		billingZip: '',
		campaignId: offer.campaignId,
		shippingId: offer.productsData[0].shippingId || '',
		billingSameAsShipping: true,
		agreeSms: false,
		fbc: getFbcValue(),
		fbp: Cookies.get('_fbp'),
		custom_fields: getCustomFields(),
		ipAddress,
		upsellCount: '1',
		upsellProductIds: '2170', // processing fee product id
		orderTotal: offer.productsData[0].price * 1 || offer.productsData[0].shippingPrice * 1 || 0,
		dynamicProductsData: {},
		isPayPalOrder: false,
	};

	const [orderData, dispatch] = useReducer(orderReducer, initialOrderState);

	function handleSaveStep(data, newIndex) {
		dispatch({
			type: 'SAVE_STEP',
			payload: data,
		});

		if (newIndex === 2) {
			klaviyoStartedCheckout(data);
		}

		if (newIndex <= 3) {
			setStepIndex(newIndex);
		}

		if (newIndex === 4) {
			onCreateOrder({ ...data, ipAddress });
		}
	}

	function handleFieldUpdate(e) {
		dispatch({
			type: 'FIELD_UPDATE',
			field: e.target.name,
			payload: xssFilters.inHTMLData(e.target.value),
		});
	}

	function handleSmsCheckboxChange(e) {
		dispatch({
			type: 'FIELD_UPDATE',
			field: 'agreeSms',
			payload: e.target.checked,
		});
	}

	useEffect(() => {
		if (offer.protectionPrice && queryParams.protection === '1') {
			dispatch({
				type: 'UPDATE_UPSELL',
				payload: {
					upsellCount: '2',
					upsellProductIds: '2170,2560', // add 2560 as an id for protection upsell
				},
			});
		}
	}, [offer.protectionPrice, queryParams.protection]);

	function handleProtectionCheckboxChange(checked) {
		const currentUpsellCount = parseInt(orderData.upsellCount);
		const currentUpsellProductIds = orderData.upsellProductIds.split(',');

		let newUpsellCount;
		let newUpsellProductIds;

		if (checked) {
			newUpsellCount = (currentUpsellCount + 1).toString();
			newUpsellProductIds = [...currentUpsellProductIds, '2560'];
		} else {
			newUpsellCount = (currentUpsellCount - 1).toString();
			newUpsellProductIds = currentUpsellProductIds.filter((id) => id !== '2560');
		}

		dispatch({
			type: 'UPDATE_UPSELL',
			payload: {
				upsellCount: newUpsellCount,
				upsellProductIds: newUpsellProductIds.join(','),
			},
		});
	}

	function handlePressBack(stepData) {
		handleSaveStep(stepData, stepIndex - 1);
		setStepIndex(stepIndex - 1);
	}

	function handleSubmitStep(newData, errors, newStepIndex) {
		let newErrors = errors;

		if (isOneLineCheckout) {
			const shippingErrors = validateStep(orderData, shippingErrorMessages);

			if (Object.keys(shippingErrors).length > 0) {
				newErrors = { ...errors, ...shippingErrors };
			}
		}

		setFormErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			// save last 4 digits of cc to the cookie
			if (newStepIndex === 4) {
				Cookies.set('last4digits', newData.creditCardNumber.slice(-4), { expires: 1 });
			}

			if (newData.email) {
				Cookies.set('email', newData.email, { expires: 1 });
			}

			handleSaveStep({ ...orderData, ...newData }, newStepIndex);
		}
	}

	function preselectCrossSells() {
		let ids = orderData.upsellProductIds.split(',');
		let count = orderData.upsellCount;
		let orderTotal = orderData.orderTotal;

		crossSells.forEach((item) => {
			if (item.preselectedOffers) {
				item.preselectedOffers.forEach((preselectedOffer) => {
					if (preselectedOffer.id === offer._id) {
						ids.push(item.variants.length > 0 ? item.variants[0].productId : item.productId);
						count = count * 1 + 1;
						orderTotal += item.price.substring(1) * 1;
					}
				});
			}
		});

		dispatch({
			type: 'SAVE_STEP',
			payload: {
				upsellProductIds: ids.filter((item) => item).join(),
				upsellCount: count.toString(),
				orderTotal,
			},
		});
	}

	function handleCrossSellAdd(id, price) {
		let ids = orderData.upsellProductIds.split(',');
		let count = orderData.upsellCount;
		let orderTotal = orderData.orderTotal;

		if (!ids.includes(id)) {
			ids.push(id);
			count = count * 1 + 1;
			orderTotal += price;
		} else {
			ids = ids.filter((item) => item !== id);
			count = count * 1 - 1;
			orderTotal -= price;
		}

		dispatch({
			type: 'SAVE_STEP',
			payload: {
				upsellProductIds: ids.filter((item) => item).join(),
				upsellCount: count.toString(),
				orderTotal,
			},
		});
	}

	function renderSteps() {
		const steps = [
			<div key='ProductSelect'>
				<ProductSelect
					offer={offer}
					products={offer.productsData}
					orderData={orderData}
					onSubmit={handleSaveStep}
					defaultVariantId={offer.defaultVariantId}
					key='ProductSelect'
					isOneLineCheckout={isOneLineCheckout}
					isOneProductCheckout={isOneProductCheckout}
					mostPopularProductIds={offer.mostPopularProductIds}
				/>
				{!isOneProductCheckout && crossSells.length > 0 && (
					<CrossSells
						crossSells={crossSells}
						upsellProductIds={orderData.upsellProductIds.split(',')}
						onAdd={handleCrossSellAdd}
					/>
				)}
			</div>,
			<ShippingInfo
				key='ShippingInfo'
				orderData={orderData}
				onPressBack={handlePressBack}
				onFieldUpdate={handleFieldUpdate}
				onSmsCheckboxChange={handleSmsCheckboxChange}
				isOneLineCheckout={isOneLineCheckout}
				onSubmitStep={handleSubmitStep}
				formErrors={formErrors}
			/>,
			<PaymentInfo
				key='PaymentInfo'
				orderData={orderData}
				onSubmit={handleSaveStep}
				onPressBack={handlePressBack}
				onFieldUpdate={handleFieldUpdate}
				onTermsClick={onTermsClick}
				onPrivacyClick={onPrivacyClick}
				isOneLineCheckout={isOneLineCheckout}
				webtvDisclaimer={offer.webtvDisclaimer}
				onSubmitStep={handleSubmitStep}
				formErrors={formErrors}
				isOneProductCheckout={isOneProductCheckout}
				onCrossSellAdd={handleCrossSellAdd}
				crossSells={crossSells}
				protectionPrice={queryParams.protection === '1' ? offer.protectionPrice : ''}
				onProtectionCheckboxChange={handleProtectionCheckboxChange}
			/>,
		];

		if (isOneLineCheckout) {
			return steps;
		} else {
			return steps[stepIndex - 1];
		}
	}

	return renderSteps();
}
